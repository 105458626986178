import { useMutation } from "@tanstack/react-query";
import { AuthAPI } from "services/API/Auth";
import ChooseNick from "./ChooseNick";
import useStore from "store";
import { useForm, SubmitHandler } from "react-hook-form";
import { valSchema } from "utils/validators/Login/ChooseNick";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";

const ChooseNickContainer = () => {
  const { email, password, nick, setNick } = useStore((state) => state);

  type Input = {
    nick: string;
  };

  const navigate = useNavigate();

  const registerReq = useMutation({
    mutationKey: ["register"],
    mutationFn: () => AuthAPI.register({ username: nick, email, password }),
    onError: (error) => {
      alert(error.response.data.email);
      navigate("/login");
    },
    onSuccess: () => navigate("/login/confirm-email"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Input>({
    resolver: zodResolver(valSchema),
  });

  const onSubmit: SubmitHandler<Input> = async (data) => {
    setNick(data.nick);
    registerReq.mutate();
  };

  return (
    <ChooseNick
      errors={errors}
      isLoading={registerReq.isPending}
      register={register}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
    />
  );
};

export default ChooseNickContainer;
