import { z } from "zod";

export const valSchema = z.object({
  code: z
    .string()
    .length(8, { message: "code must be 8 characters long" })
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "Your code contains prohibited characters",
    }),
});
