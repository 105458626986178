import type { AxiosResponse } from "axios";
import { instance } from "./instances";

export const ShopAPI = {
  getPrices: (): Promise<
    AxiosResponse<{
      [key in
        | "premium_period"
        | "premium_price"
        | "standard_period"
        | "standard_price"]: string;
    }>
  > => instance.get("/store/subscribes_prices/"),
};
