import React, { useState, useEffect, useRef, type RefObject } from "react";
import Spend from "./Spend";
import plus_logo from "image/Goals/plus.svg";
import { useQuery } from "@tanstack/react-query";
import Modal from "utils/components/Modal";
import CreatePayment from "./CreatePayment";
import Loader from "../Notifications/NotificationsWindow/Loader";
import { PeriodicSpendsAPI } from "services/API/PeriodicSpends";
import { useSetCoolScroll } from "utils/hooks/useSetCoolScroll";
import { sanitizeObject } from "utils/functions/sanitizeObject";
import DOMPurify from "dompurify";

interface PeriodicSpendsWindowProps {
  featureRef: RefObject<HTMLElement>;
  onBack: () => void;
}

const PeriodicSpendsWindow = ({
                                featureRef,
                                onBack,
                              }: PeriodicSpendsWindowProps) => {
  const { data, isPending } = useQuery({
    queryKey: ["get periodic spends"],
    queryFn: () => PeriodicSpendsAPI.getSpends().then((res) => res.data),
  });

  const [creatingPayment, setCreatingPayment] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const resizeRef = useRef<HTMLDivElement>(null);

  const [windowSize, setWindowSize] = useState(() => {
    const savedSize = localStorage.getItem('panelSize');
    if (savedSize) {
      const { width, height } = JSON.parse(DOMPurify.sanitize(savedSize));
      return { width, height };
    }
    return { width: 432, height: 750 };
  });

  useSetCoolScroll(featureRef);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        setScrollPosition(contentRef.current.scrollTop);
      }
    };

    if (contentRef.current) {
      contentRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (contentRef.current) {
        contentRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (resizeRef.current && featureRef.current) {
        const rect = featureRef.current.getBoundingClientRect();
        const aspectRatio = rect.width / rect.height;

        let newHeight = Math.max(750, Math.min(e.clientY - rect.top, 870));
        let newWidth = newHeight * aspectRatio;

        if (newWidth > 510) {
          newWidth = 510;
          newHeight = newWidth / aspectRatio;
        }

        setWindowSize({ width: newWidth, height: newHeight });
        localStorage.setItem('panelSize', JSON.stringify(sanitizeObject({ width: newWidth, height: newHeight })));
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.body.style.userSelect = '';
    };

    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      document.body.style.userSelect = 'none';

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    if (resizeRef.current) {
      resizeRef.current.addEventListener("mousedown", handleMouseDown);
    }

    return () => {
      if (resizeRef.current) {
        resizeRef.current.removeEventListener("mousedown", handleMouseDown);
      }
      document.body.style.userSelect = '';
    };
  }, [featureRef]);

  return (
    <aside
      ref={featureRef}
      className="bg-[#121212] relative top-[2.3%] left-[1.5%] overflow-hidden rounded-3xl flex flex-col items-center shadow-md shadow-[#FFA80080]"
      style={{
        width: `${windowSize.width}px`,
        height: `${windowSize.height}px`,
        minWidth: '432px',
        minHeight: '750px',
        maxWidth: '510px',
        maxHeight: '870px'
      }}
    >
      <div
        ref={contentRef}
        className="w-full h-full overflow-auto flex flex-col items-center"
      >
        <div className="flex font-vela items-center justify-between w-full p-4">
          <button
            onClick={onBack}
            className="text-2xl bg-transparent border-none cursor-pointer text-white hover:text-[#ffa800] transition-colors duration-200"
          >
            ←
          </button>
          <h2 className="text-[20px] text-white flex-grow text-center">
            Periodic Spends
          </h2>
        </div>
        <div className="flex flex-col items-center w-full">
          {isPending ? (
            <Loader beforeBg="18,18,18" />
          ) : (
            data.map(
              ({
                 title,
                 schedule,
                 category,
                 account,
                 id,
                 day,
                 amount,
                 currency,
                 category_icon,
               }) => (
                <Spend
                  title={title}
                  schedule={schedule}
                  id={id}
                  day={day}
                  key={`${title} payment`}
                  amount={amount}
                  currency={currency}
                  account={account}
                  category={category}
                  category_icon={category_icon}
                />
              )
            )
          )}
        </div>
        <button
          className="mt-4 mb-4"
          onClick={() => setCreatingPayment(true)}
          type="button"
        >
          <img src={plus_logo} className="!rounded-full" alt="add payment" />
        </button>
      </div>
      <div
        ref={resizeRef}
        className="absolute bottom-0 right-0 w-4 h-4 bg-[#ffa800] cursor-nwse-resize"
        style={{
          borderTopLeftRadius: '25px',
        }}
      >
      </div>
      {creatingPayment && (
        <Modal setActive={setCreatingPayment}>
          <CreatePayment setCreatingPayment={setCreatingPayment} />
        </Modal>
      )}
    </aside>
  );
};

export default PeriodicSpendsWindow;