import { z } from "zod";
import { commentObj } from "../defObjects";

const amountObj = z
  .string()
  .refine(
    (value) => {
      const parsedValue = Number.parseFloat(value);
      return !Number.isNaN(parsedValue) && parsedValue > 0;
    },
    { message: "the amount must be a positive number" }
  )
  .refine((value) => !value.includes("<") && !value.includes(">"), {
    message: "The amount contains prohibited  characters",
  });

export const valSchema = z.object({
  comment: commentObj,
  amount: amountObj,
  //to avoid the code duplication
  accountId: amountObj,
});
