import { z } from "zod";

export const valSchema = z.object({
  nick: z
    .string()
    .max(30, { message: "Your name must be up to 30 characters long" })
    .min(2, { message: "Your name must be at least 2 characters long" })
    //a small step towards xss protection
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "Your name contains prohibited characters",
    }),
});
