import Modal from "utils/components/Modal";
import IncomingArrow from "image/Account/IncomingArrow.svg";
import { useState, type FC } from "react";
import type { OneAccount } from "utils/types/response/AccountRes";
import { createPortal } from "react-dom";
import React from "react";
import IncomingModal from "./IncomingModal";

interface Props {
  currencySymbol: string;
  Account: OneAccount;
  disabled: boolean;
}

const Incoming: FC<Props> = React.memo(
  ({ currencySymbol, Account, disabled }) => {
    const [modal, setModal] = useState<boolean>(false);
    const [showFullIncome, setShowFullIncome] = useState<boolean>(false);

    return (
      <>
        <button
          type="button"
          onClick={() => setModal(true)}
          className="w-[16px] disabled:cursor-not-allowed justify-self-center row-[1/3]"
          disabled={disabled}
        >
          <img src={IncomingArrow} alt="up arrow" />
        </button>
        <div
          onMouseEnter={() => setShowFullIncome(true)}
          onMouseLeave={() => setShowFullIncome(false)}
          className="flex-col flex relative text-[10px] "
        >
          {showFullIncome && (
            <p className="!absolute -top-3.5 bg-[#19191A] px-0.5 rounded-[3px]">
              {currencySymbol + Account.income}
            </p>
          )}
          <p className="ml-[1px] h-[12px]">
            {currencySymbol + Account?.formatted_income}
          </p>
        </div>
        <p className="ml-[1px] text-[5px] text-[#686868]">Incoming</p>
        {modal &&
          createPortal(
            <Modal setActive={setModal}>
              <IncomingModal accountId={Account?.id} setModal={setModal} />
            </Modal>,
            document.body
          )}
      </>
    );
  }
);

export default Incoming;
