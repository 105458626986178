import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";
import SpaceList from "../../Space/SpaceList/SpaceList.tsx";

import Cards from "/src/image/p_menu/Cards.svg";
import Converter from "/src/image/p_menu/Converter.svg";
import Crypt from "/src/image/p_menu/Crypt.svg";
import Dowt from "/src/image/p_menu/Dowt.svg";
import History from "/src/image/p_menu/History.svg";
import Messenger from "/src/image/p_menu/Messenger.svg";
import PeriodicSpends from "/src/image/p_menu/PeriodicSpends.svg";
import Settings from "/src/image/p_menu/Settings.svg";
import Shop from "/src/image/p_menu/Shop.svg";
import Space from "/src/image/p_menu/Space.svg";
import Statistics from "/src/image/p_menu/Statistics.svg";
import Goals from "/src/image/p_menu/Goals.svg";
import ConverterWindow from "./ConverterWindow";
import SettingsWindow from "./SettingsWindow";
import CryptoAndStocks from "./CryptoAndStocksWindow/CryptoAndStocks";
import GoalsWindow from "./GoalsWindow";
import DowtWindow from "./DowtWindow";
import PeriodicSpendsWindow from "./PeriodicSpendsWindow";
import ShopWindowContainer from "./ShopWindow";
import HistoryWindow from "./HistoryWindow/HistoryWindow.tsx";

interface MenuProps {
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  menuPos: { top: string; left: string };
}

const PremiumMenu = ({ setShowMenu, menuPos }: MenuProps) => {
  interface Button {
    id: string;
    icon: string;
    onClick?: () => void;
    title: string;
  }
  const navigate = useNavigate();

  const [showing, setShowing] = useState<
    | "Menu"
    | "Converter"
    | "Settings"
    | "SpaceMenu"
    | "Crypto&Stocks"
    | "Goals"
    | "Dowt"
    | "Periodic spends"
    | "Shop"
    | "History"
  >("Menu");

  const toggleSpaceMenu = () => {
    setShowing(showing === "SpaceMenu" ? "Menu" : "SpaceMenu");
    if (!(showing === "SpaceMenu")) {
      navigate("/space");
    }
  };

  const toggleCryptoAndStocks = () => {
    setShowing(showing === "Crypto&Stocks" ? "Menu" : "Crypto&Stocks");
  };

  const Buttons: Button[] = [
    {
      id: "ids.settings",
      icon: Settings,
      title: "Settings",
      onClick: () => setShowing("Settings"),
    },
    {
      id: "ids.converter",
      icon: Converter,
      title: "Converter",
      onClick: () => setShowing("Converter"),
    },
    {
      id: "ids.goals",
      icon: Goals,
      title: "Goals",
      onClick: () => setShowing("Goals"),
    },
    {
      id: "ids.statistics",
      icon: Statistics,
      onClick: () => navigate("/statistics"),
      title: "Statistics",
    },
    {
      id: "ids.history",
      icon: History,
      onClick: () => setShowing("History"),
      title: "History",
    },
    {
      id: "ids.shop",
      icon: Shop,
      title: "Shop",
      onClick: () => {
        alert(
          "This store offers pre-orders for subscriptions. By purchasing a subscription now, you'll be able to use it starting November 15th, when it becomes active."
        );
        setShowing("Shop");
      },
    },
    {
      id: "ids.dowt",
      icon: Dowt,
      onClick: () => setShowing("Dowt"),
      title: "Dowt",
    },
    { id: "ids.cards", icon: Cards, title: "Cards" },
    {
      id: "ids.periodic_spends",
      icon: PeriodicSpends,
      title: "Periodic spends",
      onClick: () => setShowing("Periodic spends"),
    },
    {
      id: "space",
      icon: Space,
      onClick: toggleSpaceMenu,
      title: "Space",
    },
    {
      id: "ids.crypt",
      icon: Crypt,
      title: "Crypt",
      onClick: toggleCryptoAndStocks,
    },
    { id: "ids.messenger", icon: Messenger, title: "Messenger" },
  ];

  const ref = useRef(null);
  const featureRef = useRef(null);

  const handleClickOutside = (e) => {
    setShowMenu(false);
    setShowing("Menu");
  };

  useOnClickOutside(ref, handleClickOutside);

  switch (showing) {
    case "Converter":
      return (
        <ConverterWindow
          converterRef={featureRef}
          onBack={() => setShowing("Menu")}
        />
      );
    case "Settings":
      return (
        <SettingsWindow
          settingsRef={featureRef}
          onBack={() => setShowing("Menu")}
        />
      );
    case "Goals":
      return (
        <GoalsWindow goalsRef={featureRef} onBack={() => setShowing("Menu")} />
      );
    case "Dowt":
      return (
        <DowtWindow dowtRef={featureRef} onBack={() => setShowing("Menu")} />
      );
    case "Periodic spends":
      return (
        <PeriodicSpendsWindow
          featureRef={featureRef}
          onBack={() => setShowing("Menu")}
        />
      );
    case "Shop":
      return (
        <ShopWindowContainer
          featureRef={featureRef}
          onBack={() => setShowing("Menu")}
        />
      );
    case "History":
      return (
        <HistoryWindow
          historyRef={featureRef}
          onClose={() => setShowing("Menu")}
        />
      );
    default:
      return (
        <div
          ref={ref}
          id="premium_menu"
          className={`
            pointer-events-auto flex flex-col
            h-auto w-[5rem] absolute
            gap-[5.2%] rounded-3xl bg-[#1c1c1c] bg-opacity-70 px-[0.6rem] pb-3
            ${showing === "Menu" ? "top-[50%] -translate-y-[50%] left-4" : ""}
          `}
        >
          {showing === "SpaceMenu" ? (
            <SpaceList />
          ) : showing === "Crypto&Stocks" ? (
            <CryptoAndStocks onClose={() => setShowing("Menu")} />
          ) : (
            Buttons.map((button) => (
              <button
                onClick={button.onClick}
                id={button.id}
                key={button.id}
                type="button"
                title={button.title}
                className="flex h-[3.7rem] mt-3 w-full items-center justify-center
                  rounded-[1rem] border border-[#727171]
                  bg-[#262626] hover:border-[#ffa800]"
              >
                <img src={button.icon} alt="button" />
              </button>
            ))
          )}
        </div>
      );
  }
};

export default PremiumMenu;
