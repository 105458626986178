import { useEffect, useRef, useState } from "react";
import cl from "./DowtWindow.module.scss";
import { useSetCoolScroll } from "utils/hooks/useSetCoolScroll";

const Advice = ({ advice }: { advice: string }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [currentAdviceText, setCurrentAdviceText] = useState("");
  const adviceRef = useRef<HTMLElement>();
  useSetCoolScroll(adviceRef);
  let timeout;
  useEffect(() => {
    if (currentAdviceText.length < advice.length) {
      timeout = setTimeout(() => {
        if (currentAdviceText.length < advice.length) {
          setCurrentAdviceText((prev) => prev + advice[currentTextIndex]);
          setCurrentTextIndex((prev) => prev + 1);
        }
      }, 20);
    }
    if (currentTextIndex === 2) {
      setCurrentAdviceText((prev) => prev.substring(0, 1) + advice[1]);
    }
  }, [advice, currentTextIndex]);
  const handleSkip = () => {
    setCurrentAdviceText(advice);
    clearTimeout(timeout);
  };
  return (
    <section
      onClick={handleSkip}
      className={`${cl.loader} !bg-[#1b1b1b] overflow-y-auto min-h-10 rounded-t-none rounded-b-3xl !block !my-4 p-2 text-2xl`}
      ref={adviceRef}
    >
      {currentAdviceText} <span className="relative">|</span>
      {currentAdviceText.length !== 0 && (
        <button
          onClick={() =>
            navigator.clipboard
              .writeText(currentAdviceText)
              .catch(() => alert("Failed to copy text"))
          }
          className="absolute active:bg-[#1A1A1A] duration-75 px-1 rounded-xl text-lg top-2 text-[#CCCCCC] bg-[#333333] right-3"
          type="button"
        >
          copy
        </button>
      )}
    </section>
  );
};

export default Advice;
