import type { StateCreator } from "zustand";

interface State {
  showSpinner: boolean;
}

interface Actions {
  setShowSpinner: (showSpinner: State["showSpinner"]) => void;
}

export interface SpinnerSlice extends State, Actions {}

export const createSpinnerSlice: StateCreator<SpinnerSlice> = (set) => ({
  showSpinner: false,
  setShowSpinner: (showSpinner: boolean) => set(() => ({ showSpinner })),
});
