import TransfetingForm from "./TransferingForm";
import { useMutation } from "@tanstack/react-query";
import { GoalsAPI } from "services/API/Goals";
import { valSchema } from "utils/validators/Goals/TransferToGoal";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import type { Dispatch, SetStateAction } from "react";
import { queryClient } from "main";
import useStore from "store/store";
import showErrorMessage from "utils/constants/showErrorMessage";

export interface IFormInputs {
  from_account: string;
  amount: string;
}

const FormContainer = ({
  setGoalEvent,
  goalId,
}: {
  setGoalEvent: Dispatch<SetStateAction<"transfering" | "editing" | "none">>;
  goalId: number;
}) => {
  const { mutate, isPending } = useMutation({
    mutationKey: ["transfet to goal"],
    mutationFn: (data: TransferToGoalReq) => GoalsAPI.transferToGoal(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get goals"] });
      setGoalEvent("none");
    },
    onError: showErrorMessage,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: zodResolver(valSchema),
  });
  const { accounts } = useStore();
  const onSubmit: SubmitHandler<IFormInputs> = ({ from_account, amount }) => {
    mutate({
      from_account: Number.parseInt(from_account),
      amount: Number.parseFloat(amount),
      to_goal: goalId,
    });
  };
  return (
    <TransfetingForm
      setGoalEvent={setGoalEvent}
      errors={errors}
      accounts={accounts}
      isLoading={isPending}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    />
  );
};

export default FormContainer;
