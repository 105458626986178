import React from "react";

export default ({ r, strokeWidth, angle }) => {
  const firstY = 125 - r * Math.cos(0);
  const firstX = 250;
  const lastY = 125 - r * Math.cos(angle);
  const lastX = 250 + r * Math.sin(angle);

  return (
    <defs>
      <filter id="glow2">
        <feGaussianBlur in="SourceAlpha" stdDeviation="2" result="blur" />
        <feFlood floodColor="#ffa800" result="color" />
        <feComposite in="color" in2="blur" operator="in" result="coloredBlur" />
        <feMerge>
          <feMergeNode in="coloredBlur" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
  );
};
