import { useEffect, useRef, useState, type FC, type RefObject } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ProfileAPI } from "services/API/Profile";
import { type SubmitHandler, useForm } from "react-hook-form";
import type { IProfileEditReq } from "utils/types/request/ProfileReq";
import { queryClient } from "main";
import useStore from "store/store";
import Settings from "./Settings";
import { valSchema } from "utils/validators/Profile/EditProfile";
import { zodResolver } from "@hookform/resolvers/zod";
import showErrorMessage from "utils/constants/showErrorMessage";

export interface IFormInputs {
  "E-mail": string;
  Password: string;
  username: string;
}

const SettingsContainer: FC<{
  settingsRef: RefObject<HTMLDivElement>;
  onBack: () => void;
}> = ({ settingsRef, onBack }) => {
  const navigate = useNavigate();

  const { data, isPending } = useQuery({
    queryKey: ["profile view"],
    queryFn: () => ProfileAPI.ProfileView().then((res) => res.data),
  });
  const { username, email, tag, language } = data ? data : {};
  const { setLoggedIn } = useStore();

  const logoutReq = useMutation({
    mutationKey: ["logout"],
    mutationFn: () => ProfileAPI.Logout(),
    onSuccess: () => {
      window.localStorage.setItem("logout", Date.now());
      localStorage.clear();
      setLoggedIn(false);
      navigate("/login");
    },
    onError: showErrorMessage,
  });
  const logout = () => {
    if (window.confirm("You are going to logout from your account")) {
      logoutReq.mutate();
    }
  };
  const [isEditing, setIsEditing] = useState<boolean>(false);
  // If it is true, the ConfirmEmailContainer modal is displayed
  const [confirmingEmail, setConfirmingEmail] = useState<boolean>(false);
  // If it is true, the ConfirmPasswordChangeContainer modal is displayed
  const [emailVerification, setEmailVerification] = useState<boolean>(false);

  const editReq = useMutation({
    mutationKey: ["edit profile"],
    mutationFn: (newProfileData: IProfileEditReq) =>
      ProfileAPI.EditProfile(newProfileData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile view"] });
      setIsEditing(false);
    },
    onError: showErrorMessage,
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: zodResolver(valSchema),
  });
  // when rendering data is undefined, so then after the query completes,
  // when the date contains data, I set it to the default values ​​of the form
  useEffect(() => {
    reset({
      username,
      "E-mail": email,
      Password: "",
    });
  }, [username, email, reset]);
  const [newEmail, setNewEmail] = useState(undefined);
  const onSubmit: SubmitHandler<IFormInputs> = async (newData) => {
    const isChangingEmail = newData["E-mail"] !== email;
    await editReq
      .mutateAsync({
        ...(newData.username !== username && { username: newData.username }),
        ...(isChangingEmail && { email: newData["E-mail"] }),
        ...(isChangingPassword && { password: newData.Password }),
      })
      .then(() => {
        if (isChangingEmail) {
          setConfirmingEmail(true);
          setNewEmail(newData["E-mail"]);
        } else if (newData.Password !== "") {
          setEmailVerification(true);
        }
      });
  };
  return (
    <Settings
      isLoading={isPending || logoutReq.isPending || editReq.isPending}
      watch={watch}
      settingsRef={settingsRef}
      handleSubmit={handleSubmit}
      username={username}
      register={register}
      tag={tag}
      email={email}
      language={language}
      onSubmit={onSubmit}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      logout={logout}
      confirmingEmail={confirmingEmail}
      setConfirmingEmail={setConfirmingEmail}
      newEmail={newEmail}
      errors={errors}
      onBack={onBack}
      emailVerification={emailVerification}
      setEmailVerification={setEmailVerification}
    />
  );
};

export default SettingsContainer;
