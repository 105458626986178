import getSpaceId from "utils/constants/getSpaceId";
import { instance } from "./instances";
import type { AxiosResponse } from "axios";
import type { Spend } from "utils/types/response/PeriodicSpendsRes";

interface ICreateSpend {
  title: string;
  account_pk: number;
  category_pk: number;
  amount: number;
  [key in ("day_of_week" | "day_of_month")]: number;
}

export const PeriodicSpendsAPI = {
  getSpends: (): Promise<AxiosResponse<Spend[]>> =>
    instance.get(`/my_spaces/${getSpaceId()}/periodic_spends/`),
  createPayment: (data: ICreateSpend) =>
    instance.post(`/my_spaces/${getSpaceId()}/create_periodic_spend/`, data),
  deleteSpend: (spendId: number) =>
    instance.delete(
      `/my_spaces/${getSpaceId()}/delete_periodic_spend/${spendId}/`
    ),
  editSpend: ({ data, spendId }: { data: ICreateSpend; spendId: number }) =>
    instance.put(
      `/my_spaces/${getSpaceId()}/periodic_spends/${spendId}/`,
      data
    ),
};
