import React from "react";
import IconBox from "./IconBox";

export default ({
  data,
  visibleIcon,
  setVisibleIcon,
}: {
  data: Record<string, string>;
  visibleIcon: null | string;
  setVisibleIcon: React.Dispatch<React.SetStateAction<null | string>>;
}) => {
  const sum = Object.values(data)
    .map((value) => Number.parseInt(value))
    .reduce((a, b) => a + b, 0);
  let previousAnglesSum = 0;
  return Object.keys(data).map((icon) => {
    const angle = (Number.parseInt(data[icon]) / sum) * 2 * Math.PI;
    const isSmallAngle = angle < 0.25;
    const endAngle = previousAnglesSum + angle / 2;
    previousAnglesSum += angle;
    const x = 290 + (isSmallAngle ? 120 : 98) * Math.sin(endAngle);
    const y = 113 + (isSmallAngle ? -120 : -98) * Math.cos(endAngle);
    if (angle < 0.25) {
      if (visibleIcon === icon) {
        return (
          <IconBox
            x={x}
            y={y}
            icon={`https://spendsplif.com/icons/${icon}.svg`}
            key={icon}
            setVisibleIcon={setVisibleIcon}
          />
        );
      }
    } else {
      return (
        <image
          y={y}
          x={x}
          href={`https://spendsplif.com/icons/${icon}.svg`}
          key={icon}
          width={20}
          height={20}
        />
      );
    }
  });
};
