import React, { useContext } from "react";
import { StatisticsContext } from "components/StatisticsPage";
import EmptyArcs from "./EmptyArcs";
import Arcs from "./Arcs";
import Labels from "./Labels";

export default () => {
  const Goals = useContext(StatisticsContext).data?.Goals;
  const { percent, period } =
    useContext(StatisticsContext) && useContext(StatisticsContext);

  const chartPeriod = period + (percent ? "_Percent" : "");
  const dotunStrokeWidth = 50;
  const dotunRadius = 90;
  const data = Goals[chartPeriod];
  const sortedEntries = Object.entries(data).sort((a, b) =>
    a[0].localeCompare(b[0]),
  );
  const sortedData = Object.fromEntries(sortedEntries);

  return (
    <svg style={{ width: "100%" }} viewBox="0 0 600 250">
      <rect
        x="0"
        y="0"
        width="600"
        height="250"
        rx="30"
        ry="30"
        fill="#101010"
      />
      <Labels data={sortedData} />
      <EmptyArcs
        number={Object.keys(sortedData).length}
        dotunRadius={dotunRadius}
        dotunStrokeWidth={dotunStrokeWidth}
      />
      <Arcs
        data={Object.values(sortedData)}
        dotunRadius={dotunRadius}
        dotunStrokeWidth={dotunStrokeWidth}
      />
    </svg>
  );
};
