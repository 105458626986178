import React from 'react';
import EditSpace from "./EditSpace.tsx";

const SpaceEditContainer = () => {
  return (
    <div>
      <EditSpace />
    </div>
  );
};

export default SpaceEditContainer;
