import Title from "components/HomePage/Circles/AccountCircle/CreateAccount/Title";
import checklist_logo from "image/Goals/сhecklist.svg";
import FormContainer from "./FormContainer";
import type { Dispatch, SetStateAction } from "react";

const CreateGoal = ({
  setCreatingGoal,
}: { setCreatingGoal: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <>
      <Title
        header="Create goal"
        title={`Change the fields as you see
         fit and click the create button`}
        image={checklist_logo}
        className="rounded-tr-3xl py-8"
      />
      <FormContainer setCreatingGoal={setCreatingGoal} />
    </>
  );
};

export default CreateGoal;
