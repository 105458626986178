import OutgoingArrow from "image/Account/OutgoingArrow.svg";
import { useState, type FC } from "react";
import { createPortal } from "react-dom";
import Modal from "utils/components/Modal";
import type { OneAccount } from "utils/types/response/AccountRes";
import OutgoingModal from "./OutgoingModal";

interface Props {
  currencySymbol: string;
  Account: OneAccount;
  disabled: boolean;
}

const Outgoing: FC<Props> = ({ currencySymbol, Account, disabled }) => {
  const [modal, setModal] = useState<boolean>(false);
  const [showFullSpend, setShowFullSpend] = useState<boolean>(false);

  return (
    <>
      <button
        type="button"
        className="w-[16px] mt-1 disabled:cursor-not-allowed justify-self-center row-[3/5]"
        onClick={() => setModal(true)}
        disabled={disabled}
      >
        <img src={OutgoingArrow} alt="down arrow" />
      </button>
      <div
        onMouseEnter={() => setShowFullSpend(true)}
        onMouseLeave={() => setShowFullSpend(false)}
        className="flex flex-col ml-[1px] mt-1 h-[12px] text-[10px] col-[2/3] relative"
      >
        {showFullSpend && (
          <p className="!absolute -top-3.5 bg-[#19191A] px-0.5 rounded-[3px]">
            {currencySymbol + Account.spend}
          </p>
        )}
        <p className="h-[12px]">{currencySymbol + Account?.formatted_spend}</p>
      </div>
      <p className="ml-[1px] text-[5px] text-[#686868]">Expenses</p>
      {modal &&
        createPortal(
          <Modal setActive={setModal}>
            <OutgoingModal setModal={setModal} accountId={Account.id} />
          </Modal>,
          document.body
        )}
    </>
  );
};

export default Outgoing;
