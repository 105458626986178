import { type RefObject, useEffect } from "react";

export const useSetCoolScroll = (ref: RefObject<HTMLElement>) => {
  useEffect(() => {
    if (ref.current) {
      const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");
      ref.current.style.scrollbarColor = isFirefox
        ? undefined
        : "#909296 #31363b";
      ref.current.style.scrollbarWidth = isFirefox ? undefined : "thin";
    }
  }, []);
};
