import Title from "components/HomePage/Circles/AccountCircle/CreateAccount/Title";
import checklist_logo from "image/Goals/сhecklist.svg";
import EditGoalFormContainer from "./EditGoalFormContainer";
import type { Dispatch, SetStateAction } from "react";

const EditGoal = ({
  setGoalEvent,
  goalTitle,
  goal,
  goalId,
}: {
  goalTitle: string;
  goal: string;
  goalId: number;
  setGoalEvent: Dispatch<SetStateAction<"transfering" | "editing" | "none">>;
}) => {
  return (
    <>
      <Title
        header="Edit Goal"
        title={`Change the fields as you see
        fit and click the edit button`}
        image={checklist_logo}
        className="rounded-tr-3xl py-8"
      />
      <EditGoalFormContainer
        goalId={goalId}
        goal={goal}
        goalTitle={goalTitle}
        setGoalEvent={setGoalEvent}
      />
    </>
  );
};

export default EditGoal;
