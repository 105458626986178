import cl from "./Loader.module.scss";
import coin from "image/coin.svg";

type BeforeBg = "16,16,16" | "18,18,18" | "24,24,24";

const getGradient = (beforeBg: BeforeBg) => {
  switch (beforeBg) {
    case "16,16,16":
      return "before:bg-[linear-gradient(to_right,rgba(16,16,16,0)0%,rgba(16,16,16,1)50%,rgba(16,16,16,0)100%)]";
    case "18,18,18":
      return "before:bg-[linear-gradient(to_right,rgba(18,18,18,0)0%,rgba(18,18,18,1)50%,rgba(18,18,18,0)100%)]";
    case "24,24,24":
      return "before:bg-[linear-gradient(to_right,rgba(24,24,24,0)0%,rgba(24,24,24,1)50%,rgba(24,24,24,0)100%)]";
  }
};

const Loader = ({ beforeBg = "16,16,16" }: { beforeBg?: BeforeBg }) => {
  const beforeBgGrad = getGradient(beforeBg);
  return (
    <div className="h-[90%] w-full flex justify-center items-center">
      <div className={`${cl.container} ${beforeBgGrad}`}>
        <img src={coin} alt="loader" className="w-[40%] m-auto animate-fade" />
      </div>
    </div>
  );
};

export default Loader;
