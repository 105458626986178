import coin from "image/coin.svg";
import cl from "./Loader.module.scss";

const Loader = () => {
  return (
    <div
      draggable={false}
      className={`h-[100dvh] ${
        window.ReactNativeWebView ? "bg-mobile_login" : "bg-login"
      } bg-no-repeat bg-cover flex bg-black w-dvw`}
    >
      <div className={cl.container}>
        <img className={`animate-fade w-[150px]`} src={coin} alt="loading" />
      </div>
    </div>
  );
};

export default Loader;
