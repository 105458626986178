import React, { type Dispatch, type SetStateAction } from "react";
import cl from "../CreateAccount/Form.module.scss";
import type {
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import type { IIncomeAccountReq } from "utils/types/request/AccountRequest";
import withErrorShow from "utils/hoc/withErrorShow";

const TransactionsForm = React.memo(
  ({
    firstInputClass,
    setModal,
    handleSubmit,
    register,
    onSubmit,
  }: {
    firstInputClass: string;
    setModal: Dispatch<SetStateAction<boolean>>;
    register: UseFormRegister<IIncomeAccountReq>;
    handleSubmit: UseFormHandleSubmit<IIncomeAccountReq>;
    onSubmit: SubmitHandler<IIncomeAccountReq>;
  }) => {
    return (
      <>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid text-[20px] items-center gap-y-4 p-[24px] grid-rows-[48px_48px_auto] grid-cols-[minmax(0,2fr)_minmax(0,1fr)_minmax(0,1fr)]"
        >
          <input
            {...register("amount")}
            placeholder="Amount"
            type="number"
            step="0.01"
            className={`${cl.field} ${firstInputClass} `}
          />
          <input
            {...register("comment")}
            placeholder="Comment"
            className={`${cl.field} bg-hashtag_logo !rounded-r-none !col-[1/3]`}
          />
          <p className="bg-[#242424] text-center py-[13px] rounded-r-xl text-[#6E6E6E]">
            Optional
          </p>
          <button
            onClick={() => setModal(false)}
            type="button"
            className="rounded-l-xl text-white bg-[#181818] py-[12px]"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-[#FFA800] my-2 rounded-r-xl py-[12px] col-[2/4]"
          >
            Add
          </button>
        </form>
      </>
    );
  }
);

export default withErrorShow(TransactionsForm);
