import React from "react";
import { colors } from "./Arcs";
import currencyToSymbolMap from "currency-symbol-map";

export default ({
  data,
}: { data: Record<string, { Goal_amount?: string; Collected: string }> }) => {
  return Object.keys(data).map((label, i) => {
    const color = colors[i];
    const ok = data[label].Collected.split(" ");
    const value = data[label].Goal_amount
      ? ok[0] + currencyToSymbolMap(ok[1])
      : data[label].Collected;
    return (
      <React.Fragment key={label}>
        <circle
          r="0.3rem"
          cx="200"
          cy={27 + i * 20}
          fill={color}
          strokeWidth={color === "#ffa800" ? "1" : 0}
          stroke="#976321"
        />
        <text x={210} y={30 + i * 20} fontSize="0.5rem" fill={color}>
          {`${value} `}
          {label.length > 10 ? `${label.slice(0, 10)}...` : label}
        </text>
      </React.Fragment>
    );
  });
};
