import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { AuthAPI } from "services/API/Auth";
import useStore from "store/store";
import App from "App.tsx";
import { useNavigate } from "react-router-dom";

const AppContainer = () => {
  const { loggedIn, setLoggedIn } = useStore((state) => state);

  const checkAuth = useMutation({
    mutationKey: ["checkAuth"],
    mutationFn: () => AuthAPI.checkAuth(),
    onSuccess: () => setLoggedIn(true),
  });

  const navigate = useNavigate();

  useEffect(() => {
    const syncLogout = (e: StorageEvent) => {
      if (e.key === "logout") {
        navigate("/login");
      }
    };
    window.addEventListener("storage", syncLogout);
    if (localStorage.getItem("token")) {
      checkAuth.mutateAsync();
    }
  }, []);

  return <App isLoading={checkAuth.isPending} loggedIn={loggedIn} />;
};

export default AppContainer;
