import SpaceList from "./SpaceList.tsx";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SpaceAPI } from "services/API.ts";
import { CircleItem } from "utils/types/response/SpaceResponse";

import OneUserIcon from "image/spaces_p/OneUserIcon.svg";
import TwoUsersIcon from "image/spaces_p/TwoUsersIcon.svg";
import ThreeUsersIcon from "image/spaces_p/ThreeUsersIcon.svg";
import { sanitizeObject } from "utils/functions/sanitizeObject";
import useStore from "store";

const SpaceListContainer = () => {
  const { setSpaces, spaces } = useStore();
  const selectedIndexFromStorage = Number.parseInt(
    localStorage.getItem("selectedIndex")
  );
  const [selectedIndex, setSelectedIndex] = useState(
    isNaN(selectedIndexFromStorage) ? 1 : selectedIndexFromStorage
  );
  const navigate = useNavigate();

  const plusButtons = [
    { id: "create1", title: "+" },
    { id: "create2", title: "+" },
  ];

  const circleItems: CircleItem[] = [
    plusButtons[0],
    ...spaces.map((space) => ({
      id: space.id.toString(),
      title: space.title,
      currency: space.currency,
      members_count: space.members_count,
    })),
    plusButtons[1],
  ];

  const getVisibleItems = (index: number) => {
    const start = Math.max(index - 2, 0);
    const end = Math.min(index + 2, circleItems.length - 1);
    return circleItems.slice(start, end + 1);
  };

  useEffect(() => {
    SpaceAPI.getMySpaces()
      .then((spaces) => {
        setSpaces(spaces);
      })
      .catch((error) => {
        console.error("Error fetching spaces:", error);
      });
  }, [circleItems.length]);

  const handleCircleSelect = (index: number) => {
    setSelectedIndex(index);
  };

  const handleOpenSpace = () => {
    const selectedItem = circleItems[selectedIndex];
    if (selectedItem && selectedItem.id.startsWith("create")) {
      navigate("/space/create");
    } else {
      const selectedSpace = spaces.find(
        (space) => selectedItem && space.id.toString() === selectedItem.id
      );
      if (selectedSpace) {
        localStorage.setItem(
          "space",
          JSON.stringify(sanitizeObject(selectedSpace))
        );
        navigate("/");
      }
    }
  };

  const handlePrevSpace = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex === 0 ? circleItems.length - 1 : prevIndex - 1
    );
  };

  const handleNextSpace = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex === circleItems.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    localStorage.setItem("selectedIndex", selectedIndex.toString());
  }, [selectedIndex]);

  const handleEditSpace = () => {
    const selectedItem = circleItems[selectedIndex];
    if (selectedItem && !selectedItem.id.startsWith("create")) {
      navigate(`/space/edit/${selectedItem.id}`);
    }
  };

  const handleLeaveSpace = () => {
    const selectedItem = circleItems[selectedIndex];
    if (selectedItem && !selectedItem.id.startsWith("create")) {
      const spaceId = parseInt(selectedItem.id, 10);
      SpaceAPI.leaveFromSpace(spaceId)
        .then(() => {
          setSpaces(spaces.filter((space) => space.id !== spaceId));
        })
        .catch((error) => {
          console.error("Error leaving space:", error);
        });
    }
  };

  const getIconForMembers = (count: number) => {
    if (count === 1) return OneUserIcon;
    if (count === 2) return TwoUsersIcon;
    return ThreeUsersIcon;
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case "ArrowLeft":
        handlePrevSpace();
        break;
      case "ArrowRight":
        handleNextSpace();
        break;
      case "Enter":
        handleOpenSpace();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  });
  const visibleItems = getVisibleItems(selectedIndex);

  return (
    <SpaceList
      visibleItems={visibleItems}
      circleItems={circleItems}
      selectedIndex={selectedIndex}
      handleCircleSelect={handleCircleSelect}
      getIconForMembers={getIconForMembers}
      spaces={spaces}
      handleEditSpace={handleEditSpace}
      handlePrevSpace={handlePrevSpace}
      handleLeaveSpace={handleLeaveSpace}
      handleNextSpace={handleNextSpace}
      handleOpenSpace={handleOpenSpace}
    />
  );
};

export default SpaceListContainer;
