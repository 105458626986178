import utilCl from "utils/Classes.module.scss";
import withErrorShow from "utils/hoc/withErrorShow";
import withLoader from "utils/hoc/withLoader";
import cl from "../CreateGoal/Form.module.scss";
import type { IFormProps } from "utils/types/formProps";
import type { IFormInputs } from "./TransferingFormContainer";
import type { Dispatch, FC, SetStateAction } from "react";
import type { OneAccount } from "utils/types/response/AccountRes";

interface Props extends IFormProps<IFormInputs> {
  setGoalEvent: Dispatch<SetStateAction<"transfering" | "editing" | "none">>;
  accounts: OneAccount[];
}

const Form: FC<Props> = ({
  handleSubmit,
  onSubmit,
  register,
  setGoalEvent,
  accounts,
}) => (
  <form className={cl.form} onSubmit={handleSubmit(onSubmit)}>
    <select {...register("from_account")}>
      <option value="" disabled>
        Select an account
      </option>
      {accounts.map((account) => (
        <option key={account.id} value={account.id}>
          {account.title} | {account.balance_converted} {account.currency}
        </option>
      ))}
    </select>
    <input autoComplete="off" placeholder="Goal" {...register("amount")} />
    <button
      type="button"
      onClick={() => setGoalEvent("none")}
      className={`${utilCl.black_button} rounded-l-xl py-3`}
    >
      Cancel
    </button>
    <button className={`${utilCl.yellow_button} rounded-r-xl`} type="submit">
      Transfer
    </button>
  </form>
);

export default withLoader(withErrorShow(Form));
