export const icons = {
  ArrowsAction: ["Exit"],
  Astronomy: [
    "Atom",
    "Black Hole",
    "Earth",
    "Infinity",
    "Planet",
    "Rocket",
    "Stars",
  ],
  BuildingInfrastructure: [
    "Buildings",
    "City",
    "Garage",
    "Home",
    "Hospital",
    "Shop",
    "Three Buildings",
  ],
  BusinessStatistic: [
    "Chart Square",
    "Chart",
    "Course Down",
    "Course Up",
    "Diagram Down",
    "Diagram Up",
    "Pie Chart",
    "Presentation Graph",
    "Round Graph",
  ],
  Call: ["Phone Calling", "Phone"],
  DesignTools: [
    "Crop Minimalistic",
    "Layers",
    "Paint Roller",
    "Pallete",
    "Ruler Angular",
    "Ruler Cross Pen",
    "Ruler",
  ],
  ElectronicDevices: [
    "Airbuds Case Open",
    "Airbuds",
    "Bluetooth Circle",
    "CPU",
    "Devices",
    "Diskette",
    "Display",
    "Flash Drive",
    "Gameboy",
    "Gamepad",
    "Headphones Round Sound",
    "Headphones Round",
    "Keyboard",
    "Laptop Minimalistic",
    "Laptop",
    "Lightbulb Bolt",
    "Monitor Smartphone",
    "Monitor",
    "Printer",
    "SSD Square",
    "Server Minimalistic",
    "Smartphone Rotate",
    "Smartphone Vibration",
    "TV",
    "Telescope",
  ],
  EssentionalUI: [
    "Accessibility",
    "Bolt Circle",
    "Box",
    "Broom",
    "Cat",
    "Confetti",
    "Cup Star",
    "Ferris Wheel",
    "Hanger",
    "Paper Bin",
    "Paw",
    "T shirt",
    "Umbrella",
  ],
  Folders: ["File", "Folder"],
  FoodKitchen: [
    "Bottle",
    "Burger",
    "Cake",
    "Chef Hat",
    "Dish",
    "Donut",
    "Meat",
    "Tea Cup",
    "Wineglass Triangle",
  ],
  Hands: ["Hand_Heart", "Hand_Money"],
  HomeFurniture: [
    "Armchair",
    "Bath",
    "Bed",
    "Bedside Table",
    "Chair",
    "Chandelier",
    "Closet",
    "Condicioner",
    "Fridge",
    "Lamp",
    "Smart Vacuum Cleaner",
    "Sofa",
    "Speaker",
    "Washing Machine",
  ],
  Like: [
    "Dislike",
    "Heart Shine",
    "Heart Unlock",
    "Heart",
    "Hearts",
    "Like",
    "Medal Ribbon Star",
    "Medal Ribbons Star",
    "Star Shine",
    "Star",
  ],
  List: ["Checklist Minimalistic"],
  MapLocation: ["Compass Big", "Global", "Globus"],
  Medicine: [
    "Bone",
    "DNA",
    "Heart Pulse",
    "Jar Of Pills",
    "Pill",
    "Stethoscope",
  ],
  MessagesConversation: ["Dialog", "Forward", "Letter", "Plain"],
  Money: [
    "Banknote",
    "Card",
    "Cart",
    "Dollar",
    "Euro",
    "Money Bag",
    "Ruble",
    "Safe",
    "Sale",
    "Ticket",
    "Wallet",
  ],
  NatureTravel: ["Flame"],
  NetworkITProgramming: ["Bug", "Code", "Hashtag", "Programming"],
  Notes: ["Clipboard"],
  School: [
    "Book Bookmark Minimalistic",
    "Book",
    "Calculator Minimalistic",
    "Notebook Minimalistic",
  ],
  Security: ["Eye Closed", "Eye", "Lock", "Shield Network"],
  "SettingsFine Tuning": ["Settings", "Tuning Square"],
  Sports: [
    "Balls",
    "Basketball",
    "Bicycling Round",
    "Bicycling",
    "Bowling",
    "Dumbbell Large Minimalistic",
    "Dumbbell Small",
    "Dumbbell",
    "Dumbbells",
    "Football",
    "Golf",
    "Hiking",
    "Meditation",
    "Ranking",
    "Rugby",
    "Running",
    "Skateboarding",
    "Swimming",
    "Tennis",
    "Treadmill",
    "Volleyball",
    "Water Sun",
  ],
  TextFormatting: ["Backspace"],
  Time: ["Calendar", "Hourglass Line", "Watch Round"],
  TransportPartsService: [
    "Bus",
    "Electric Refueling",
    "Gas Station",
    "Scooter",
    "Tram",
    "Wheel Angle",
    "Wheel",
  ],
  VideoAudioSound: [
    "Camera Minimalistic",
    "Clapperboard Play",
    "Gallery",
    "Microphone",
    "Music Note",
    "Podcast",
    "Soundwave",
    "Video Library",
    "Videocamera",
  ],
  Weather: ["Snowflake", "Sun"],
};

export const arrayIcons = Object.values(icons).flat();
