import { z } from "zod";

export const valSchema = z.object({
  "E-mail": z
    .string()
    .email({ message: "This is not a valid email" })
    .max(50, "this email is too long")
    .min(5, "this email is too short")
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "The email contains prohibited characters",
    })
    .optional(),
  Password: z
    .string()
    //a small step towards xss protection
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "The password contains prohibited characters",
    })
    .refine(
      (value) => {
        if (value === "") {
          return true;
        }

        if (value.length < 8 || value.length > 24) {
          return false;
        }

        if (/^\d+$/.test(value)) {
          return false;
        }

        const letters = value.match(/[a-zA-Z]/g) || [];
        const numbers = value.match(/\d/g) || [];
        return letters.length >= 4 && numbers.length >= 1;
      },
      {
        message:
          "The password must be 8-24 characters long, contain more than 4 letters, at least 1 number, and not be all numeric",
      }
    )
    .optional(),
  username: z
    .string()
    .max(30, { message: "Your name must be up to 30 characters long" })
    .min(2, { message: "Your name must be at least 2 characters long" })
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "Your name contains prohibited characters",
    }),
});
