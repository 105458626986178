import user_logo from "image/Settings/user_logo.svg";
import React from "react";
import type {
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import type { IFormInputs } from "../SettingsContainer";
import cl from "./ProfilePreview.module.scss";
import Inf from "./Inf";

const ProfilePreview = React.memo(
  ({
     username,
     email,
     language,
     tag,
     isEditing,
     handleSubmit,
     onSubmit,
     register,
     watch,
   }: {
    username: string;
    email: string;
    language: string;
    tag: string;
    isEditing: boolean;
    register: UseFormRegister<IFormInputs>;
    handleSubmit: UseFormHandleSubmit<IFormInputs>;
    onSubmit: SubmitHandler<IFormInputs>;
    watch: UseFormWatch<IFormInputs>;
  }) => {
    const fields = [
      { title: "E-mail", content: email },
      { title: "Password", content: "********" },
      { title: "Language", content: language },
    ];
    return (
      <form
        id="Account-form"
        onSubmit={handleSubmit(onSubmit)}
        className={cl.form}
      >
        <article>
          <img src={user_logo} alt="user logo" />
        </article>
        {fields.map(({ title, content }, i) => (
          <Inf
            watch={watch}
            key={title}
            index={i}
            title={title}
            content={content}
            isEditing={isEditing}
            register={register}
          />
        ))}
        <div className="w-full text-center">
          <div className="h-[33px] mt-4">
            {isEditing ? (
              <input
                {...register("username")}
                className="w-full bg-transparent text-white text-[22px] text-center outline-none"
              />
            ) : (
              <p className="w-full text-white text-[22px]">{username}</p>
            )}
          </div>
          <p className="w-full text-[#636363] text-[16px]">{`${username}#${tag}`}</p>
        </div>
      </form>
    );
  },
);

export default ProfilePreview;