import React, { useState, Dispatch, SetStateAction, useMemo } from "react";
import Modal from "utils/components/Modal";
import Title from "../../Circles/AccountCircle/CreateAccount/Title.tsx";
import Check from "../../../../image/Account/Check.svg";
import { createPortal } from "react-dom";
import TransactionsFormContainer from "./TransactionFormContainer.tsx";

type OutgoingModalProps = {
  setModal: Dispatch<SetStateAction<boolean>>;
  categoryId: number;
};

const OutgoingModal: React.FC<OutgoingModalProps> = ({
  setModal,
  categoryId,
}) => {
  // const {
  //   data: accounts,
  //   isLoading,
  //   error,
  // } = useQuery({
  //   queryKey: ["view account second"],
  //   queryFn: () => AccountAPI.ViewAccounts(),
  // });
  return createPortal(
    <Modal setActive={setModal}>
      <Title
        image={Check}
        header={"Enter expense"}
        title={
          "Enter the amount of money expensed\nand comment (this is optional)"
        }
        className="rounded-tr-[24px] h-40"
      />
      <TransactionsFormContainer setModal={setModal} categoryId={categoryId} />
    </Modal>,
    document.body
  );
};

export default OutgoingModal;
