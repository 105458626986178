import memoise from "./memoise";

const formatLargeNumber = (num: number) => {
  if (num >= 1e12) {
    return `${Number.parseFloat((num / 1e12).toFixed(1))}t`;
  }
  if (num >= 1e9) {
    return `${Number.parseFloat((num / 1e9).toFixed(1))}b`;
  }
  if (num >= 1e6) {
    return `${Number.parseFloat((num / 1e6).toFixed(1))}m`;
  }
  if (num >= 1e3) {
    return `${Number.parseFloat((num / 1e3).toFixed(1))}k`;
  }
  return num.toString();
};

export default memoise(formatLargeNumber);
