import { useMutation } from "@tanstack/react-query";
import { queryClient } from "main";
import type { Dispatch, SetStateAction } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { ProfileAPI } from "services/API/Profile";
import type { IConfirmNewEmail } from "utils/types/request/ProfileReq";
import ConfirmEmail from "./ConfirmEmail";
import { zodResolver } from "@hookform/resolvers/zod";
import { valSchema } from "utils/validators/Profile/ConfirmNewEmail";
import showErrorMessage from "utils/constants/showErrorMessage";

const ConfirmEmailContainer = ({
  oldEmail,
  newEmail,
  setConfirmingEmail,
}: {
  oldEmail: string;
  newEmail: string;
  setConfirmingEmail: Dispatch<SetStateAction<boolean>>;
}) => {
  const { mutate, isPending } = useMutation({
    mutationKey: ["confirm-new-email"],
    mutationFn: (data: IConfirmNewEmail) => ProfileAPI.ConfirmNewEmail(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile view"] });
      setConfirmingEmail(false);
    },
    onError: showErrorMessage,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IConfirmNewEmail>({
    resolver: zodResolver(valSchema),
  });
  const onSubmit: SubmitHandler<IConfirmNewEmail> = (data) => {
    mutate(data);
  };

  return (
    <ConfirmEmail
      isLoading={isPending}
      errors={errors}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      newEmail={newEmail}
      oldEmail={oldEmail}
    />
  );
};

export default ConfirmEmailContainer;
