import { UseFormRegister, UseFormWatch } from "react-hook-form";
import { IFormInputs } from "../SettingsContainer.tsx";

const Inf = ({
               title,
               content,
               index,
               isEditing,
               register,
               watch,
             }: {
  title: "E-mail" | "Password" | "Language";
  content: string;
  index: number;
  isEditing: boolean;
  register: UseFormRegister<IFormInputs>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  return (
    <section
      className={`
        ${index === 0 ? "rounded-tr-3xl" : ""}
        ${index === 2 ? "rounded-br-3xl" : ""}
      `}
    >
      <p className="text-white text-[22px]">{title}</p>
      <div className="w-full h-[24px]">
        {isEditing && index !== 2 ? (
          <input
            className="w-full bg-transparent text-[#636363] text-[16px] outline-none"
            autoComplete={title === "Password" ? "off" : "on"}
            {...register(title)}
          />
        ) : (
          <p className="w-full text-[#636363] text-[16px]">{content}</p>
        )}
      </div>
    </section>
  );
};

export default Inf;