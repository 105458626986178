import { type MouseEventHandler, type ReactNode, useState } from "react";
import cl from "./Subscription.module.scss";

const Subsction = ({
  image,
  title,
  titleColor,
  desription,
  details,
  buttonTextColor,
  buttonBg,
  cost,
  costBg,
  costTextColor,
  period,
  action,
  containerClass,
  handleButtonClick,
}: {
  title: "Premium" | "Standard";
  titleColor: "#ffaa00" | "#00c2ff";
  desription: string;
  details: string | ReactNode;
  buttonTextColor: "black" | "white";
  buttonBg: "#fea800" | "#00c2ff";
  cost: string;
  costBg: "#3e2901" | "#003445";
  costTextColor: "#c29131" | "#2aa1ca";
  period: string;
  action: string;
  image: ReactNode;
  containerClass: string;
  handleButtonClick: MouseEventHandler<HTMLButtonElement>;
}) => {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <article className={containerClass ? containerClass : ""}>
      {image}
      <div className={cl.description}>
        <p className="text-2xl mb-3" style={{ color: titleColor }}>
          {title}
        </p>
        <p className="text-md">{desription}</p>
        <div className={`text-[#787878] my-3 ${cl.details}`}>
          included:
          <br />
          {showDetails ? (
            <div
              onClick={() => setShowDetails(false)}
              className="whitespace-pre-line"
            >
              {details}
            </div>
          ) : (
            <button
              type="button"
              onClick={() => setShowDetails(true)}
              className="text-[#606060] underline"
            >
              Show details
            </button>
          )}
        </div>
        <div className="flex">
          <button
            className="font-normal py-1 px-3 rounded-l-md w-[39%]"
            style={{
              background: buttonBg,
              color: buttonTextColor,
            }}
            type="button"
            title="Select"
            onClick={handleButtonClick}
          >
            Select
          </button>

          <div
            className="font-normal grow text-center inline h-full py-[5px] relative w-[50%] rounded-r-md"
            style={{
              background: costBg,
              color: costTextColor,
            }}
          >
            {action && (
              <p className="absolute -right-3 -top-3.5 text-[#4faf3d] font-bold">
                {action}
              </p>
            )}
            {cost}/{period}
          </div>
        </div>
      </div>
    </article>
  );
};

export default Subsction;
