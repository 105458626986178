import trash_logo from "image/Goals/trash.svg";
import edit_logo from "image/Goals/edit.svg";
import cl from "./PeriodicSpends.module.scss";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "main";
import Loader from "../Notifications/NotificationsWindow/Loader";
import { useState, type Dispatch, type FC, type SetStateAction } from "react";
import Modal from "utils/components/Modal";
import showErrorMessage from "utils/constants/showErrorMessage";
import type { Spend } from "utils/types/response/PeriodicSpendsRes";
import safe from "image/expenses/Safe.svg";
import { PeriodicSpendsAPI } from "services/API/PeriodicSpends";
import EditSpend from "./EditSpend";

const Spend: FC<Spend> = ({
  title,
  id,
  schedule,
  day,
  amount,
  currency,
  category_icon,
  category,
  account,
}) => {
  const { mutate, isPending } = useMutation({
    mutationKey: ["delete periodic spend"],
    mutationFn: PeriodicSpendsAPI.deleteSpend,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["get periodic spends"] }),
    onError: showErrorMessage,
  });
  const [editingSpend, setEditiginSpend] = useState<boolean>(false);
  const actions = [
    { icon: edit_logo, onClick: () => setEditiginSpend(true) },
    {
      icon: trash_logo,
      onClick: () => {
        if (window.confirm("You are going to delete your periodic spend")) {
          mutate(id);
        }
      },
    },
  ];

  return (
    <>
      <section className={cl.container}>
        {isPending ? (
          <Loader beforeBg="24,24,24" />
        ) : (
          <>
            <div>
              <p>{title.length <= 18 ? title : `${title.slice(0, 15)}...`}</p>
              {actions.map((el, i) => (
                <button
                  key={el.icon}
                  type="button"
                  className={`bg-[#3c3600cb] duration-300 hover:bg-[#2b2a009f] py-4 px-5  ${
                    i === actions.length - 1 ? "rounded-tr-2xl" : ""
                  }`}
                  onClick={el.onClick}
                >
                  <img className="h-[25px]" src={el.icon} alt="action button" />
                </button>
              ))}
            </div>
            <article className="rounded-t-3xl mt-4">
              <p>Periodicity</p>
              <p>{schedule}</p>
              <p className="ml-2">{day}</p>
            </article>
            <article>
              <p>Amount</p>
              <p className="!bg-orange !text-black">{amount}</p>
              <p className="ml-2">{currency}</p>
            </article>
            <article className="rounded-b-3xl">
              <p>Info</p>
              <p className="!inline">
                <img
                  src={safe}
                  className="h-6 inline relative top-[10%] mr-2 mx-auto"
                  alt="safe logo"
                />
                {account}
              </p>
              <p className="ml-2 !inline">
                <img
                  src={`https://spendsplif.com/icons/${category_icon}.svg`}
                  className="h-6 inline relative top-[10%] mr-2 mx-auto"
                  alt="safe logo"
                />
                {category}
              </p>
            </article>
          </>
        )}
      </section>
      {editingSpend && (
        <Modal setActive={setEditiginSpend}>
          <EditSpend
            spendAmount={amount}
            spendTitle={title}
            spendId={id}
            setEditingSpend={setEditiginSpend}
            accountName={account}
            categoryName={category}
          />
        </Modal>
      )}
    </>
  );
};

export default Spend;
