import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppContainer from "AppContainer.tsx";
import { GoogleOAuthProvider } from "@react-oauth/google";

export const queryClient = new QueryClient();
const clientId =
  "1026773878584-1ei93c6boeklt98a1ahbdq3f5mf78fuo.apps.googleusercontent.com";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={clientId}>
          <AppContainer />
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
