import cl from "./Header.module.scss";
import { useContext } from "react";
import { StatisticsContext } from "..";
import PeriodButtons from "./PeriodButtons";
import Backspace from "image/CreateCategory/svg/Bold/Backspace.svg";
import { useNavigate } from "react-router-dom";

export default () => {
  const { setPercent, period, setPeriod, percent, currency } =
    useContext(StatisticsContext);
  const navigate = useNavigate();
  const periodButtons = ["Week", "Month", "3 months", "Year"];
  const isMobile =
    window.ReactNativeWebView ||
    /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
      navigator.userAgent,
    );
  const urlPath = window.location.pathname;
  const isStatisticsPage =
    urlPath === "/statistics" || urlPath === "/statistics/";

  return (
    <header className={cl.header}>
      {!isMobile && (
        <button
          type="button"
          onClick={() => navigate(isStatisticsPage ? "/" : "/statistics")}
        >
          <img className="h-[3rem] ml-2 sm:ml-10" alt="back" src={Backspace} />
        </button>
      )}
      <span className="grow" />
      <PeriodButtons
        periodButtons={periodButtons}
        period={period}
        setPeriod={setPeriod}
      />
      {/* Percent or currency */}
      <div>
        <button
          type="button"
          onClick={() => setPercent(false)}
          className={!percent ? cl.active_button : ""}
        >
          {currency}
        </button>
        <button
          type="button"
          onClick={() => setPercent(true)}
          className={percent ? cl.active_button : ""}
        >
          %100
        </button>
      </div>
    </header>
  );
};
