import type { AxiosResponse } from "axios";
import { instance } from "./instances";
import type {
  IHowManyUnseenRes,
  INotification,
} from "utils/types/response/NotificationsRes";
import type { IUpdateSeenReq } from "utils/types/request/NotificationsReq";

export const NotificationsAPI = {
  getNotifications: (count: number): Promise<AxiosResponse<INotification[]>> =>
    instance.post("/notifications/list/", { count }),
  howManyUnseen: (): Promise<AxiosResponse<IHowManyUnseenRes>> =>
    instance.get("/notifications/unseen_count/"),
  updateSeen: (data: IUpdateSeenReq) =>
    instance.put("/notifications/update_viewer/", { notifications: data }),
};
