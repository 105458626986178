import utilCl from "utils/Classes.module.scss";
import withErrorShow from "utils/hoc/withErrorShow";
import withLoader from "utils/hoc/withLoader";
import cl from "./Form.module.scss";
import type { IFormProps } from "utils/types/formProps";
import type { IFormInputs } from "./FormContainer";
import type { Dispatch, FC, SetStateAction } from "react";
import type { OneAccount } from "utils/types/response/AccountRes";
import type { OneCategory } from "utils/types/response/CategoriesRes";
import ChooseDay from "./ChooseDay/ChooseDay";

interface Props extends IFormProps<IFormInputs> {
  setCreatingPayment: Dispatch<SetStateAction<boolean>>;
  submitButtonText: string;
  accounts: OneAccount[];
  categories: OneCategory[];
}

const Form: FC<Props> = ({
  handleSubmit,
  onSubmit,
  register,
  setCreatingPayment,
  submitButtonText = "Create",
  accounts,
  categories,
}) => (
  <form className={cl.form} onSubmit={handleSubmit(onSubmit)}>
    <ChooseDay register={register} />
    <input
      autoComplete="off"
      placeholder="Title of this payment"
      {...register("title")}
    />
    <input autoComplete="off" placeholder="Amount" {...register("amount")} />
    <select {...register("account_pk")}>
      <option value="" disabled>
        Select an account
      </option>
      {accounts.map((account) => (
        <option key={account.id} value={account.id}>
          {account.title} | {account.balance_converted} {account.currency}
        </option>
      ))}
    </select>
    <select {...register("category_pk")}>
      <option value="" disabled>
        Select an account
      </option>
      {categories.map((category) => (
        <option key={category.id.toString()} value={category.id}>
          {/* {account.title} | {account.balance_converted} {account.currency} */}
          {category.title}
        </option>
      ))}
    </select>
    <button
      type="button"
      onClick={() => setCreatingPayment(false)}
      className={`${utilCl.black_button} rounded-l-xl py-3`}
    >
      Cancel
    </button>
    <button className={`${utilCl.yellow_button} rounded-r-xl`} type="submit">
      {submitButtonText}
    </button>
  </form>
);

export default withLoader(withErrorShow(Form));
