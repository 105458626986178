import { addDays, format, subMonths } from "date-fns";
import { useMemo } from "react";

const calculatePeriods = (
  start: Date,
  data: { [data: string]: string },
  percent: boolean,
  currency: string,
) => {
  // Get an array of dates from the data object
  const dates = Object.keys(data);
  const today = new Date();
  const periodLengthInDays = Math.ceil(
    (today.getTime() - start.getTime()) / (1000 * 60 * 60 * 24),
  );

  const partLengthInDays = Math.floor(periodLengthInDays / 4);
  const periods = [];
  for (let i = 0; i < 4; i++) {
    // Define the start of the period
    const periodStartDate = addDays(start, i * partLengthInDays);

    // Define the end of the period
    let periodEndDate;
    if (i === 3) {
      periodEndDate = today; // The last period ends on the end date
    } else {
      periodEndDate = addDays(periodStartDate, partLengthInDays - 1);
    }

    // Select the dates that fall within the current period
    const periodDates = dates.filter((date) => {
      const thisDate = new Date(new Date(date).setHours(0, 0, 0, 0));
      return thisDate >= periodStartDate && thisDate <= periodEndDate;
    });

    // Calculate the total value for the current period
    const periodValues = periodDates.map((date) =>
      Number.parseFloat(data[date]),
    );
    const totalPeriodValue = periodValues.reduce((a, b) => a + b, 0);

    // Form the date range of the period
    const periodRange = `${format(periodStartDate, "d MMM")} - ${format(
      periodEndDate,
      "d MMM",
    )}`;

    // Add the period object to the array of periods

    periods.push({
      period: periodRange,
      value: totalPeriodValue,
      label:
        totalPeriodValue === 0
          ? undefined
          : percent
            ? `%${totalPeriodValue}`
            : currency + totalPeriodValue,
    });
  }
  return periods;
};

const toChartWeek = (
  data: {
    [data: string]: string;
  },
  percent: boolean,
  currency: string,
) => {
  const result = [];
  const today = new Date();
  for (let i = 6; i >= 0; i--) {
    const d = new Date();
    d.setDate(today.getDate() - i);
    const formattedDate = d.toISOString().split("T")[0];
    const dayOfWeek = d.toLocaleString("en-US", { weekday: "short" });
    const value = Number.parseInt(data[formattedDate]) || 0;
    result.push({
      period: dayOfWeek,
      value,
      label: value === 0 ? undefined : percent ? `%${value}` : currency + value,
    });
  }
  return result;
};

export const useDataToChart = (
  data: Record<string, string>,
  period: string,
  percent: boolean,
  currency: string,
) =>
  useMemo(() => {
    if (period === "Week" || period === "Week_Percent") {
      return toChartWeek(data, percent, currency);
    }
    const periodStart = subMonths(
      new Date().setHours(0, 0, 0, 0),
      period === "Three_month" || period === "Three_month_Percent"
        ? 3
        : period === "Month" || period === "Month_Percent"
          ? 1
          : 12,
    );

    return calculatePeriods(periodStart, data, percent, currency);
  }, [data, period]);
