import Title from "components/HomePage/Circles/AccountCircle/CreateAccount/Title";
import archive_up_logo from "image/Goals/archive_up.svg";
import type { Dispatch, SetStateAction } from "react";
import TransferingFormContainer from "./TransferingFormContainer";

const TransferingToGoal = ({
  setGoalEvent,
  goalId,
}: {
  setGoalEvent: Dispatch<SetStateAction<"transfering" | "editing" | "none">>;
  goalId: number;
}) => {
  return (
    <>
      <Title
        header="Enter transfer"
        title={`As you replenish your goal you get 
          closer and closer to fulfillig it`}
        image={archive_up_logo}
        className="rounded-tr-3xl py-8 font-vela"
      />
      <TransferingFormContainer goalId={goalId} setGoalEvent={setGoalEvent} />
    </>
  );
};

export default TransferingToGoal;
