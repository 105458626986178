import { addDays, format, subMonths, parse } from "date-fns";
import { useMemo } from "react";
import type { ExpensesData } from "utils/types/response/StatisticsResponse";

const calculatePeriods = (
  data: ExpensesData,
  start: Date,
  percent: boolean,
  currency,
) => {
  // Get an array of dates from the data object
  const dates = Object.keys(data);
  const today = new Date();
  const periodLengthInDays = Math.ceil(
    (today.getTime() - start.getTime()) / (1000 * 60 * 60 * 24),
  );

  const partLengthInDays = Math.floor(periodLengthInDays / 4);
  const rezult: { x: string; y: number; label: string }[][] = [[], [], []];
  const parameters = ["Category", "Loss", "Recurring Spending"];
  // parameters.
  for (let i = 0; i < 4; i++) {
    // Define the start of the period
    const periodStartDate = addDays(start, i * partLengthInDays);

    // Define the end of the period
    let periodEndDate;
    if (i === 3) {
      periodEndDate = today; // The last period ends on the end date
    } else {
      periodEndDate = addDays(periodStartDate, partLengthInDays - 1);
    }

    // Select the dates that fall within the current period
    const periodDates = dates.filter((date: string) => {
      const thisDate = new Date(new Date(date).setHours(0, 0, 0, 0));
      return thisDate >= periodStartDate && thisDate <= periodEndDate;
    });
    parameters.forEach((el, index) => {
      // Calculate the total value for the current period
      const periodValues = periodDates.map((date) =>
        Number.parseFloat(data[date][el]),
      );

      // Form the date range of the period
      const periodRange = `${format(periodStartDate, "d MMM")} - ${format(
        periodEndDate,
        "d MMM",
      )}`;
      const totalPeriodValue = periodValues.reduce((a, b) => a + b, 0);

      // Add the period object to the array of periods
      rezult[index].push({
        x: periodRange,
        y: totalPeriodValue,
        label:
          totalPeriodValue === 0
            ? undefined
            : percent
              ? `%${totalPeriodValue}`
              : currency + totalPeriodValue,
      });
    });
  }
  return rezult;
};

const toChartWeek = (
  data: ExpensesData,
  percent: boolean,
  currency: string,
) => {
  const result: { x: string; y: number; label: string | undefined }[][] = [
    [],
    [],
    [],
  ];
  const today = new Date();
  result.forEach((arr, index) => {
    for (let i = 6; i >= 0; i--) {
      const d = new Date();
      d.setDate(today.getDate() - i);
      const formattedDate = d.toISOString().split("T")[0];
      const dayOfWeek = d.toLocaleString("en-US", { weekday: "short" });
      const value = Number.parseInt(
        data[formattedDate] ? Object.values(data[formattedDate])[index] : "0",
      );
      arr.push({
        x: dayOfWeek,
        y: value,
        label:
          value === 0 ? undefined : percent ? `%${value}` : currency + value,
      });
    }
  });

  return result;
};

export const useDataToChart = (
  data: ExpensesData,
  period: string,
  percent: boolean,
  currency: string,
) =>
  useMemo(() => {
    if (period === "Week" || period === "Week_Percent") {
      return toChartWeek(data, percent, currency);
    }
    const start = subMonths(
      new Date().setHours(0, 0, 0, 0),
      period === "Year" ? 12 : period === "Three_month" ? 3 : 1,
    );
    return calculatePeriods(data, start, percent, currency);
  }, [data, period, percent, currency]);
