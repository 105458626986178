import DOMPurify from "dompurify";

export const sanitizeObject = (obj: any) => {
  if (typeof obj === "object") {
    const sanitizedObj = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        sanitizedObj[key] =
          typeof obj[key] === "string"
            ? DOMPurify.sanitize(obj[key])
            : obj[key];
      }
    }

    return sanitizedObj;
  }
  return DOMPurify.sanitize(obj);
};
