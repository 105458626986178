import { useMutation } from "@tanstack/react-query";
import DOMPurify from "dompurify";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useForm } from "react-hook-form";
import { SpendAPI } from "services/API";
import useStore from "store";
import TransactionsForm from "./TransactionsForm";
import { valSchema } from "utils/validators/Category/SpendCategory";
import { zodResolver } from "@hookform/resolvers/zod";
import showErrorMessage from "utils/constants/showErrorMessage";
import { queryClient } from "main";

export interface IFormInputs {
  accountId: string;
  amount: string;
  comment: string;
}

const TransactionsFormContainer = ({
  setModal,
  categoryId,
}: {
  setModal: Dispatch<SetStateAction<boolean>>;
  categoryId: number;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: zodResolver(valSchema),
  });
  const { accounts, setCategoryChanged, setShowSpinner } = useStore();

  const storedSpace = localStorage.getItem("space");
  const spaceId = useMemo(
    () => (storedSpace ? JSON.parse(DOMPurify.sanitize(storedSpace)).id : null),
    [storedSpace]
  );

  const { mutate } = useMutation({
    mutationKey: ["spend account"],
    mutationFn: (data: {
      account_pk: number;
      category_pk: number;
      amount: number;
      comment?: string;
    }) => SpendAPI.spendAccount(spaceId, data),
    onSuccess: () => {
      setCategoryChanged(true);
      setShowSpinner(true);
      setModal(false);
      queryClient.invalidateQueries({ queryKey: ["get history"] });
    },
    onError: showErrorMessage,
    onSettled: () => setShowSpinner(false),
  });
  const onSubmit = handleSubmit(({ amount, comment, accountId }) => {
    if (accountId !== null && Number.parseInt(amount) > 0) {
      mutate({
        account_pk: Number.parseInt(accountId),
        category_pk: categoryId,
        amount: Number.parseInt(amount),
        comment,
      });
    } else {
      alert("Please select an account and enter a valid amount.");
    }
  });
  return (
    <TransactionsForm
      errors={errors}
      onSubmit={onSubmit}
      accounts={accounts}
      setModal={setModal}
      register={register}
    />
  );
};

export default TransactionsFormContainer;
