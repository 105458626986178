import React, { useRef, useEffect, type RefObject } from "react";
import Accounts from "./Accounts";
import ConverterFormContainer from "./ConverterFormContainer";
import { sanitizeObject } from "utils/functions/sanitizeObject";
import DOMPurify from "dompurify";

interface ConverterProps {
  converterRef: RefObject<HTMLDivElement>;
  onBack: () => void;
}

const Converter: React.FC<ConverterProps> = ({ converterRef, onBack }) => {
  const resizeRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Загрузка сохраненных размеров при монтировании компонента
    const savedSize = localStorage.getItem('panelSize');
    if (savedSize && converterRef.current) {
      const { width, height } = JSON.parse(DOMPurify.sanitize(savedSize));
      converterRef.current.style.width = `${width}px`;
      converterRef.current.style.height = `${height}px`;
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (resizeRef.current && converterRef.current) {
        const rect = converterRef.current.getBoundingClientRect();
        const aspectRatio = rect.width / rect.height;

        let newHeight = Math.max(750, Math.min(e.clientY - rect.top, 870));
        let newWidth = newHeight * aspectRatio;

        if (newWidth > 510) {
          newWidth = 510;
          newHeight = newWidth / aspectRatio;
        }

        converterRef.current.style.width = `${newWidth}px`;
        converterRef.current.style.height = `${newHeight}px`;

        // Сохранение новых размеров в localStorage
        localStorage.setItem('panelSize', JSON.stringify(sanitizeObject({ width: newWidth, height: newHeight })));
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.body.style.userSelect = '';
    };

    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      document.body.style.userSelect = 'none';

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    if (resizeRef.current) {
      resizeRef.current.addEventListener("mousedown", handleMouseDown);
    }

    return () => {
      if (resizeRef.current) {
        resizeRef.current.removeEventListener("mousedown", handleMouseDown);
      }
      document.body.style.userSelect = '';
    };
  }, [converterRef]);

  return (
    <div
      ref={converterRef}
      className="text-white p-5 font-vela font-bold bg-[#101010] rounded-3xl text-[20px] min-w-[432px] min-h-[750px] w-[432px] h-[750px] relative top-[2.3%] left-[1.5%] shadow-md shadow-[#FFA80080] flex flex-col"
      style={{ maxHeight: '870px', maxWidth: '510px', overflow: 'hidden' }}
    >
      <div className={"flex items-center justify-center mb-4 relative"}>
        <button
          onClick={onBack}
          type="button"
          className={"absolute left-0 text-2xl bg-transparent border-none cursor-pointer text-white hover:text-[#ffa800] transition-colors duration-200"}
        >
          ←
        </button>
        <h2 className={"text-[20px] text-center flex-grow"}>Converter</h2>
      </div>
      <ConverterFormContainer />
      <p className="text-[20px] mt-8 mb-2">Accounts</p>
      <Accounts />

      <div
        ref={resizeRef}
        className="absolute bottom-0 right-0 w-4 h-4 bg-[#ffa800] cursor-nwse-resize"
        style={{
          borderTopLeftRadius: '25px',
        }}
      ></div>
    </div>
  );
};

export default Converter;