import { useNavigate } from "react-router-dom";
import cl from "./Statistics.module.scss";
import Incomes from "./Incomes";
import { useContext } from "react";
import { StatisticsContext } from "..";
import Expenses from "./Expenses";
import Balance from "./Balance";
import Savings from "./Savings";
import Recurring from "./Recurring";
import Categories from "./Categories";

export default () => {
  const { period } = useContext(StatisticsContext);
  const data = useContext(StatisticsContext)?.data;

  const components = [
    Expenses,
    Balance,
    Incomes,
    Savings,
    Categories,
    Recurring,
  ];
  const categoriesList = [
    "Expenses",
    "Balance",
    "Incomes",
    "Savings",
    "Categories",
    "Recurring",
  ];
  const navigate = useNavigate();
  const Statistics = categoriesList.map((el, i) => ({
    Component: components[i],
    onClick: () =>
      navigate(el === "Recurring" ? "recurring-payments" : el.toLowerCase()),
    Analys:
      el === "Savings"
        ? data?.Goals[`Analyze_${period}`]
        : el === "Recurring"
        ? data?.Recurring_Payments[`Analyze_${period}`]
        : el === "Balance"
        ? data?.Balance[period].analysis
        : data
        ? data[el][`Analyze_${period}`]
        : undefined,
  }));

  const isMobile =
    window.ReactNativeWebView ||
    /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
      navigator.userAgent
    );
  return (
    <div className={cl.container}>
      {Statistics.map(({ Component, Analys, onClick }, i) => (
        <div key={i} className="rounded-[22px]">
          <button
            type="button"
            className={`w-full ${i === 5 ? "h-[77%]" : null}`}
            onClick={isMobile ? undefined : onClick}
          >
            {/* I use fontSize & height only for the Recurring component */}
            {<Component fontSize="15px" height="100%" />}
          </button>
          <p
            className={`text-center text-[20px] font-bold ${
              i === 5 ? "h-[23%]" : null
            }`}
          >
            {Analys}
          </p>
        </div>
      ))}
    </div>
  );
};
