import AutoComplete from "components/LoginPage/ChooseCurrency/AutoComplete";
import cl from "../../Circles/AccountCircle/CreateAccount/Form.module.scss";
import withLoader from "utils/hoc/withLoader";
import withErrorShow from "utils/hoc/withErrorShow";
import {
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { IFormInputs } from "./ConverterFormContainer";
import { FC } from "react";

const CurrencyField = ({
                         setValue,
                         register,
                         fieldName,
                       }: {
  setValue: UseFormSetValue<IFormInputs>;
  register: UseFormRegister<IFormInputs>;
  fieldName: keyof IFormInputs;
}) => (
  <AutoComplete
    placeholder="Currency"
    inputClass={`bg-[#1b1b1b] ${cl.field} w-full max-w-[470px] h-[46px] !rounded-lg bg-cash_logo`}
    ulClass="!left-0 !w-auto !w-[80vw] !max-w-[377px]"
    register={register}
    fieldName={fieldName}
    onClickElement={(e) =>
      setValue(fieldName, e.target.innerText, {
        shouldValidate: true,
      })
    }
  />
);

interface Props {
  handleSubmit: UseFormHandleSubmit<IFormInputs>;
  register: UseFormRegister<IFormInputs>;
  setValue: UseFormSetValue<IFormInputs>;
  onSubmit: SubmitHandler<IFormInputs>;
  result?: string;
}
const ConverterForm: FC<Props> = ({
                                    handleSubmit,
                                    register,
                                    setValue,
                                    onSubmit,
                                    result,
                                  }) => {
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="bg-[#141414] h-auto flex flex-col p-5 rounded-xl"
    >
      <CurrencyField
        setValue={setValue}
        register={register}
        fieldName="from_currency"
      />
      <input
        type="number"
        step="0.01"
        required
        placeholder="Amount"
        className={`${cl.field} w-full max-w-[470px] h-[46px] self-start mt-5 mb-8 !rounded-lg bg-balance_logo bg-[#1b1b1b]`}
        {...register("amount")}
      />
      <CurrencyField
        setValue={setValue}
        register={register}
        fieldName="to_currency"
      />
      <button
        className="bg-[#FFA800] self-start w-full max-w-[470px] my-5 text-black h-[48px] rounded-xl"
        type="submit"
      >
        Convert
      </button>
      <p
        className={`bg-[#1b1b1b] py-2.5 rounded-lg pl-3 text-[#6E6E6E] align-middle w-full max-w-[470px] ${
          result ? "!text-white" : ""
        }`}
      >
        {result ? result : "Result"}
      </p>
    </form>
  );
};

export default withErrorShow(withLoader(ConverterForm));
