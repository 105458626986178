import { instance } from "./instances";
import type { AxiosResponse } from "axios";
import getSpaceId from "utils/constants/getSpaceId";
import type { StatisticsRes } from "utils/types/response/StatisticsResponse";

export const StatisticsAPI = {
  get(spaceId: number | typeof NaN): Promise<AxiosResponse<StatisticsRes>> {
    if (spaceId || getSpaceId()) {
      return instance.get(`/my_spaces/${spaceId || getSpaceId()}/statistic/`);
    }
    alert("You`re not autorized");
    window.location.href = "/welcome/";
  },
};
