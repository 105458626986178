import { StateCreator } from "zustand";

export interface DialogSlice {
  showDialog: boolean;
  setShowDialog: (showDialog: boolean) => void;
}

export const createDialogSlice: StateCreator<DialogSlice> = (set) => ({
  showDialog: false,
  setShowDialog: (showDialog) => set({ showDialog }),
});
