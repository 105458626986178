import { useState, type MutableRefObject } from "react";
import cl from "./ShopWindow.module.scss";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ShopAPI } from "services/API/Shop";
import DOMPurify from "dompurify";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import ShopWindow from "./ShopWindow";
import Loader from "utils/components/Loader/Loader";
import { instance } from "services/API/instances";

export interface IFormInputs {
  agreement: boolean;
  subscription: "standard" | "premium";
}

const ShopWindowContainer = ({
                               featureRef,
                               onBack, // Добавляем проп
                             }: {
  featureRef: MutableRefObject<null>;
  onBack: () => void; // Добавляем тип для пропа
}) => {
  const standardDetails = (
    <div className={cl.details}>
      <p>
        - <span>50 categories</span> for flexible organization of your expenses
      </p>
      <p>
        - <span>50 financial advices from Dowt every month</span> - receive
        expert recommendations
      </p>
      <p>
        - <span>10 goals</span> for setting and achieving your financial plans
      </p>
      <p>
        - <span>10 slots for regular expenses</span> - easily control recurring
        payments
      </p>
      - <span>6 accounts </span>for simplified tracking of all your assets
    </div>
  );
  const premiumDetails = (
    <div className={cl.details}>
      <p>
        {" "}
        - <span>Unlimited categories </span>for maximum flexibility
      </p>
      <p>
        - <span>100 financial advices from Dowt every month</span> - draw
        inspiration and knowledge
      </p>
      <p>
        - <span>Unlimited financial goals</span> - set and achieve as many goals
        as you want
      </p>
      <p>
        - <span>Unlimited slots for regular expenses </span>- perfect control
        over all payments
      </p>
      <p>
        - <span>Unlimited accounts</span> for complete tracking of your assets
      </p>
      <p>
        <span>Also:</span>
      </p>
      <p>
        - <span>Spaces feature</span> - create spaces and add other users to
        them. Manage finances with your whole family or company from different
        devices
      </p>
      <p>
        - <span>View stock and cryptocurrency prices</span> - keep your finger
        on the pulse of the market
      </p>
      {/* soon 
      <p>
        - <span>Communities feature</span> - read articles and news on financial
        topics, study market analytics, and exchange experiences
      </p> */}
    </div>
  );

  const { data } = useQuery({
    queryKey: ["get prices"],
    queryFn: () => ShopAPI.getPrices().then((res) => res.data),
  });
  const regex = /~(.*?)~/g;
  const { premium_period, premium_price, standard_period, standard_price } =
    data ? data : {};
  const [standardPeriod, standardAction] =
    DOMPurify.sanitize(standard_period).split(regex);
  const [premiumPeriod, premiumAction] =
    DOMPurify.sanitize(premium_period).split(regex);
  const {
    data: paymentData,
    isPending,
    mutateAsync,
  } = useMutation({
    mutationKey: ["get client secret"],
    mutationFn: (plan: "standard" | "premium") =>
      instance
        .post("store/create_checkout_session/", { plan })
        .then((res) => res.data),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<IFormInputs>({
    resolver: zodResolver(
      z.object({
        agreement: z.literal(true, {
          errorMap: () => ({
            message: "You have not accepted the license",
          }),
        }),
        subscription: z
          .string({ message: "You have not selected a subscription" })
          .refine((val) => val === "standard" || val === "premium", {
            message: "You have not selected a subscription",
          }),
      })
    ),
  });
  const [isBuying, setIsBying] = useState(false);

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    await mutateAsync(data.subscription);
    setIsBying(true);
  };

  const subscription = watch("subscription");

  if (isPending) {
    return <Loader />;
  }
  return (
    <ShopWindow
      isPending={isPending}
      paymentData={paymentData}
      isBuying={isBuying}
      setIsBuying={setIsBying}
      errors={errors}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      watch={watch}
      subscription={subscription}
      featureRef={featureRef}
      setValue={setValue}
      standard_price={standard_price}
      standardPeriod={standardPeriod}
      standardAction={standardAction}
      standardDetails={standardDetails}
      premium_price={premium_price}
      premiumPeriod={premiumPeriod}
      premiumAction={premiumAction}
      premiumDetails={premiumDetails}
      onBack={onBack} // Передаем функцию onBack
    />
  );
};

export default ShopWindowContainer;