import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { useDataToChart } from "./useDataToChart";
import { useContext } from "react";
import { StatisticsContext } from "components/StatisticsPage";
import currencyToSymbolMap from "currency-symbol-map";
import type { IncomesType } from "utils/types/response/StatisticsResponse";
import formatLargeNumber from "utils/functions/formatLargeNumber";

export default () => {
  const Incomes = useContext(StatisticsContext).data?.Incomes as IncomesType;
  const { percent, period, currency } =
    useContext(StatisticsContext) && useContext(StatisticsContext);
  const currencySymbol = currencyToSymbolMap(currency as string) as string;

  const chartPeriod = (period +
    (percent ? "_Percent" : "")) as keyof IncomesType;

  const chartData = useDataToChart(
    Incomes[chartPeriod] as Record<string, string>,
    chartPeriod,
    percent,
    currencySymbol,
  );
  const isMobile =
    window.ReactNativeWebView ||
    /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
      navigator.userAgent,
    );
  return (
    <VictoryChart
      width={600}
      height={250}
      domainPadding={{ x: percent ? 15 : 50 }}
      containerComponent={
        <VictoryVoronoiContainer
          style={{
            touchAction: "auto",
          }}
        />
      }
    >
      <rect x="0" y="0" rx="30" ry="30" fill="#101010" />
      <VictoryLine
        labelComponent={
          <VictoryTooltip active={isMobile} flyoutStyle={{ fill: "#161616" }} />
        }
        style={{
          data: {
            stroke: "#FFA800",
            filter: "drop-shadow(0px 0px 5px rgba(255, 168, 0, 0.5))",
          },
          labels: {
            fill: "#8F8B8B",
          },
        }}
        data={chartData}
        interpolation="monotoneX"
        x="period"
        y="value"
      />

      <VictoryAxis
        domain={[0, 100]}
        // TODO
        style={{
          tickLabels: {
            fill: "#646464",
            fontWeight: "bold",
          },
          axis: { strokeWidth: 2, stroke: "#302E2A" },
        }}
        tickFormat={(tick: number) => {
          const formattedTick = formatLargeNumber(tick);
          return percent ? `%${formattedTick}` : currencySymbol + formattedTick;
        }}
        dependentAxis
        axisValue={chartData[0].period}
      />
      <VictoryAxis
        style={{
          axis: { strokeWidth: 5, stroke: "#646464" },
          tickLabels: {
            fontSize: 10,
            fontWeight: "bold",
            fill: "#646464",
          },
          grid: { strokeWidth: 2, stroke: "#302E2A" },
        }}
      />
    </VictoryChart>
  );
};
