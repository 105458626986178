import { StatisticsContext } from "components/StatisticsPage";
import { useContext } from "react";
import {
  VictoryGroup,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLegend,
  VictoryTooltip,
  VictoryContainer,
} from "victory";
import { useDataToChart } from "./useDataToChart";
import currencyToSymbolMap from "currency-symbol-map";
import type {
  ExpensesType,
  ExpensesData,
} from "utils/types/response/StatisticsResponse";
import formatLargeNumber from "utils/functions/formatLargeNumber";

export default () => {
  const Expenses = useContext(StatisticsContext).data?.Expenses as ExpensesType;
  const { percent, period, currency } =
    useContext(StatisticsContext) && useContext(StatisticsContext);
  const currencySymbol = currencyToSymbolMap(currency as string) as string;
  const chartPeriod = (period + (percent ? "_Percent" : "")) as Exclude<
    keyof ExpensesType,
    `Analyze_${keyof ExpensesType}`
  >;

  const chartData = useDataToChart(
    Expenses[chartPeriod] as ExpensesData,
    period,
    percent,
    currencySymbol,
  );
  const isMobile =
    window.ReactNativeWebView ||
    /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
      navigator.userAgent,
    );

  return (
    <VictoryChart
      domainPadding={{ x: 50 }}
      width={600}
      height={250}
      padding={{ top: 20, bottom: 60, left: 80, right: 20 }}
      containerComponent={
        <VictoryContainer
          style={{
            touchAction: "auto",
          }}
        />
      }
    >
      <rect
        x="0"
        y="0"
        width="600"
        height="250"
        rx="30"
        ry="30"
        fill="#101010"
      />
      <VictoryGroup offset={9} colorScale={["#FBC45B", "#FFA800", "#FFDA92"]}>
        {chartData.map((el, index) => (
          <VictoryBar
            labelComponent={
              <VictoryTooltip
                active={isMobile}
                flyoutStyle={{ fill: "#161616" }}
                centerOffset={{ x: index === 0 ? -25 : index === 1 ? 0 : 25 }}
              />
            }
            barWidth={8}
            key={index}
            data={el}
            style={{
              data: {
                filter: index === 1 ? "drop-shadow(0px 0px 5px #FFA800" : "",
              },
              labels: {
                fill: "#8F8B8B",
              },
            }}
          />
        ))}
      </VictoryGroup>

      <VictoryAxis
        dependentAxis
        style={{
          grid: { stroke: "#2B2B2B", strokeWidth: 1 },
          tickLabels: { fontWeight: "bold", fill: "#646464" },
          axis: { stroke: "transparent" },
        }}
        domain={typeof Expenses[chartPeriod] === "string" ? [0, 100] : []}
        tickFormat={(tick: number) => {
          const formattedTick = formatLargeNumber(tick);
          return percent ? `%${formattedTick}` : currencySymbol + formattedTick;
        }}
      />

      <VictoryAxis
        style={{
          tickLabels: {
            fontSize: 10,
            fontWeight: "bold",
            fill: "#646464",
          },
          axis: { strokeWidth: 5, stroke: "#646464" },
        }}
      />
      <VictoryLegend
        orientation="horizontal"
        x={180}
        y={220}
        style={{
          labels: {
            fontSize: 10,
            fill: (el) => el.datum.symbol.fill,
          },
        }}
        data={[
          {
            name: "Category",
            symbol: { fill: "#FBC45B" },
          },
          {
            name: "Loss",
            symbol: {
              fill: "#FFA800",
              filter: "drop-shadow(0px 0px 5px #FFA800",
            },
          },
          { name: "Recurring Spending", symbol: { fill: "#FFDA92" } },
        ]}
      />
    </VictoryChart>
  );
};
