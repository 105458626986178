import type { AxiosResponse } from "axios";
import type {
  RegisterResponse,
  LoginResponse,
} from "utils/types/response/AuthResponse";
import type {
  RegisterRequest,
  LoginRequest,
} from "utils/types/request/AuthRequest";
import { authInstance } from "./instances";

export const AuthAPI = {
  register(data: RegisterRequest): Promise<AxiosResponse<RegisterResponse>> {
    return authInstance.post<RegisterResponse>("/register/", {
      ...data,
    });
  },

  login(data: LoginRequest): Promise<AxiosResponse<LoginResponse>> {
    return authInstance.post<LoginResponse>("/token/", { ...data });
  },

  async verifyEmail(code: string, currency: string): Promise<AxiosResponse> {
    return await authInstance.post("/verify_email/", {
      verify_code: code,
      currency: currency,
    });
  },
  checkAuth() {
    return authInstance.get("/token/refresh/");
  },
};
