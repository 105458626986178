import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SpaceAPI } from "services/API.ts";
import { useForm } from "react-hook-form";
import cl from "./member_edit.module.scss";
import UserEdit from "image/spaces_p/UserEdit.svg";

const MemberEdit = () => {
  const { spaceId, memberId } = useParams<{
    spaceId: string;
    memberId: string;
  }>();
  const [permissions, setPermissions] = useState({});
  const [memberInfo, setMemberInfo] = useState<{
    username: string;
    tag: string;
  } | null>(null);
  const { register, handleSubmit, setValue } = useForm();
  const navigate = useNavigate();

  const formatPermissionName = (name) => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const data = await SpaceAPI.getMemberPermissions(spaceId, memberId);
        const filteredData = Object.keys(data)
          .filter((key) => !["member", "space", "id", "owner"].includes(key))
          .reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
          }, {});
        setPermissions(filteredData);
        Object.keys(filteredData).forEach((key) =>
          setValue(key, filteredData[key], { shouldValidate: true }),
        );
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    const fetchMemberInfo = async () => {
      try {
        const members = await SpaceAPI.getSpaceMembers(spaceId);
        const member = members.find((m) => m.id === Number(memberId));
        if (member) {
          setMemberInfo({ username: member.username, tag: member.tag });
        }
      } catch (error) {
        console.error("Error fetching member info:", error);
      }
    };

    fetchPermissions();
    fetchMemberInfo();
  }, [spaceId, memberId, setValue]);

  const onSubmit = async (formData) => {
    try {
      await SpaceAPI.updateMemberPermissions(spaceId, memberId, formData);
      navigate(`/space/edit/${spaceId}`);
    } catch (error) {
      console.error("Error updating permissions:", error);
    }
  };

  return (
    <div className={cl.container}>
      <div className={cl.menuContainer}>
        <div className={cl.titleContainer}>
          <img src={UserEdit} alt="Icon" className={cl.icon} />
          <div>
            <h2 className={cl.title}>
              {memberInfo
                ? `${memberInfo.username}#${memberInfo.tag}`
                : "Edit Member Permissions"}
            </h2>
            <p className={cl.subtitle}>
              Manage the permissions for this member.
            </p>
          </div>
        </div>
        <form className={cl.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={cl.contentContainer}>
            <ul className={cl.ul}>
              {Object.keys(permissions).map((permission) => (
                <li key={permission} className={cl.formGroup}>
                  <label className={cl.label}>
                    {formatPermissionName(permission)}
                    <div className={cl.checkboxWrapper}>
                      <input
                        type="checkbox"
                        id={`cbx-${permission}`}
                        className={cl.checkbox}
                        {...register(permission)}
                      />
                      <label
                        htmlFor={`cbx-${permission}`}
                        className={cl.toggle}
                      >
                        <span></span>
                      </label>
                    </div>
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div className={cl.buttonsContainer}>
            <div className={cl.buttons}>
              <button
                type="button"
                className={cl.backButton}
                onClick={() => navigate(`/space/edit/${spaceId}`)}
              >
                Back
              </button>
              <button type="submit" className={cl.saveButton}>
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MemberEdit;
