import { useMutation } from "@tanstack/react-query";
import { useEffect, type Dispatch, type SetStateAction } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";
import { ProfileAPI } from "services/API/Profile";
import { zodResolver } from "@hookform/resolvers/zod";
import { valSchema } from "utils/validators/Profile/verifyPasswd";
import showErrorMessage from "utils/constants/showErrorMessage";
import ConfEmail from "components/LoginPage/ConfEmail/ConfEmail";

interface Input {
  code: string;
}

const ConfirmingPasswordChangeContainer = ({
  setEmailVerification,
}: {
  setEmailVerification?: Dispatch<SetStateAction<boolean>>;
}) => {
  const isWebView = window.ReactNativeWebView;

  const { mutate, isPending } = useMutation({
    mutationKey: ["confirm-new-email"],
    mutationFn: ProfileAPI.VerifyEmail,
    onSuccess: () => {
      if (!isWebView) {
        setEmailVerification(false);
      }
    },
    onError: showErrorMessage,
    onSettled: () => isWebView?.postMessage("Done"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Input>({
    resolver: zodResolver(valSchema),
  });
  const onSubmit: SubmitHandler<Input> = ({ code }) => {
    mutate({ reset_code: code });
  };

  useEffect(() => {
    window.ReactNativeWebView?.postMessage("Here");
  });

  const ConfEmailComponent = (
    <ConfEmail
      register={register}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      errors={errors}
      isLoading={isPending}
    />
  );

  return isWebView ? (
    <main className="h-dvh w-dvw flex flex-col justify-center text-white bg-mobile_login bg-no-repeat bg-center bg-cover px-2 ">
      <div className="bg-[#101010] rounded-3xl">{ConfEmailComponent}</div>
    </main>
  ) : (
    <> {ConfEmailComponent} </>
  );
};

export default ConfirmingPasswordChangeContainer;
