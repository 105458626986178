import { z } from "zod";
import { commentObj } from "../defObjects";

export const valSchema = z.object({
  comment: commentObj,
  amount: z
    .string()
    .refine(
      (value) => {
        const parsedValue = Number.parseFloat(value);
        return !Number.isNaN(parsedValue) && parsedValue > 0;
      },
      { message: "the amount must be a positive number" }
    )
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "The income has prohibited characters",
    }),
});
