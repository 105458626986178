import { z } from "zod";

const idSchema = z.string().refine((value) => {
  const parsedValue = Number.parseFloat(value);
  return !Number.isNaN(parsedValue) && parsedValue >= 0;
});

export const valSchema = z.object({
  title: z
    .string()
    .max(40, { message: "The title must be up to 40 characters long" })
    .min(2, { message: "The title must be at least 2 characters long" })
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "The title contains prohibited characters",
    }),
  amount: z
    .string()
    .max(15, { message: "The amount must be up to 13 characters long" })
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "The amount contains prohibited characters",
    })
    .refine(
      (value) => {
        const parsedValue = Number.parseFloat(value);
        return !Number.isNaN(parsedValue) && parsedValue > 0;
      },
      { message: "the amount must be a positive number" }
    ),
  account_pk: idSchema,
  category_pk: idSchema,
  day: z
    .string()
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "The title contains prohibited characters",
    }),
});
