import React, { useState, useEffect, useRef, type RefObject } from "react";
import Goal from "./Goal";
import plus_logo from "image/Goals/plus.svg";
import { useQuery } from "@tanstack/react-query";
import { GoalsAPI } from "services/API/Goals";
import type { currencies } from "utils/constants/currencies";
import Modal from "utils/components/Modal";
import CreateGoal from "./CreateGoal";
import Loader from "../Notifications/NotificationsWindow/Loader";
import { useSetCoolScroll } from "utils/hooks/useSetCoolScroll";
import { sanitizeObject } from "utils/functions/sanitizeObject";
import DOMPurify from "dompurify";

export interface goalI {
  id: number;
  collected: number;
  collected_converted: string;
  collected_percentage: string;
  created_date: string;
  created_time: string;
  currency: keyof typeof currencies;
  father_space: number;
  goal: string;
  goal_converted: string;
  title: string;
}

interface GoalsWindowProps {
  goalsRef: RefObject<HTMLElement>;
  onBack: () => void;
}

const GoalsWindow = ({ goalsRef, onBack }: GoalsWindowProps) => {
  const { data, isPending } = useQuery({
    queryKey: ["get goals"],
    queryFn: () => GoalsAPI.getGoals(),
  });

  const [creatingGoal, setCreatingGoal] = useState<boolean>(false);
  const resizeRef = useRef<HTMLDivElement | null>(null);
  const [windowSize, setWindowSize] = useState(() => {
    const savedSize = localStorage.getItem('panelSize');
    if (savedSize) {
      const { width, height } = JSON.parse(DOMPurify.sanitize(savedSize));
      return { width, height };
    }
    return { width: 432, height: 750 };
  });

  useSetCoolScroll(goalsRef);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (resizeRef.current && goalsRef.current) {
        const rect = goalsRef.current.getBoundingClientRect();
        const aspectRatio = rect.width / rect.height;

        let newHeight = Math.max(750, Math.min(e.clientY - rect.top, 870));
        let newWidth = newHeight * aspectRatio;

        if (newWidth > 510) {
          newWidth = 510;
          newHeight = newWidth / aspectRatio;
        }

        setWindowSize({ width: newWidth, height: newHeight });
        localStorage.setItem('panelSize', JSON.stringify(sanitizeObject({ width: newWidth, height: newHeight })));
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.body.style.userSelect = '';
    };

    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      document.body.style.userSelect = 'none';

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    if (resizeRef.current) {
      resizeRef.current.addEventListener("mousedown", handleMouseDown);
    }

    return () => {
      if (resizeRef.current) {
        resizeRef.current.removeEventListener("mousedown", handleMouseDown);
      }
      document.body.style.userSelect = '';
    };
  }, [goalsRef]);

  return (
    <aside
      ref={goalsRef}
      className="bg-[#121212] font-vela overflow-x-hidden rounded-3xl min-w-[432px] min-h-[750px] flex flex-col relative top-[2.3%] left-[1.5%] shadow-md shadow-[#FFA80080]"
      style={{
        width: `${windowSize.width}px`,
        height: `${windowSize.height}px`,
        maxHeight: '870px',
        maxWidth: '510px',
        overflow: 'hidden'
      }}
    >
      <div className="flex-grow overflow-y-auto">
        <div className="flex flex-col items-center justify-start min-h-full p-4">
          <div className="w-full top-0 z-10 bg-[#121212] pt-4 pb-2">
            <div className="flex items-center justify-between w-[90%] mx-auto relative">
              <button
                onClick={onBack}
                type="button"
                className="absolute left-0 text-2xl bg-transparent border-none cursor-pointer text-white hover:text-[#ffa800] transition-colors duration-200"
              >
                ←
              </button>
              <h2 className="text-[20px] text-center flex-grow text-white">Goals</h2>
            </div>
          </div>
          {isPending ? (
            <Loader beforeBg="18,18,18" />
          ) : (
            data.data.map((goal: goalI) => (
              <Goal
                title={goal.title}
                created_date={goal.created_date}
                created_time={goal.created_time}
                key={goal.id}
                currency={goal.currency}
                goal_converted={goal.goal_converted}
                collected_percentage={goal.collected_percentage}
                id={goal.id}
                goal={goal.goal}
                collected_converted={goal.collected_converted}
              />
            ))
          )}
        </div>

        <div className="bottom-0 bg-[#121212] py-4">
          <button
            className="m-auto block w-auto h-auto"
            onClick={() => setCreatingGoal(true)}
            type="button"
          >
            <img src={plus_logo} className="!rounded-full" alt="add goal" />
          </button>
        </div>
      </div>

      {creatingGoal && (
        <Modal setActive={setCreatingGoal}>
          <CreateGoal setCreatingGoal={setCreatingGoal} />
        </Modal>
      )}

      <div
        ref={resizeRef}
        className="absolute bottom-0 right-0 w-4 h-4 bg-[#ffa800] cursor-nwse-resize"
        style={{
          borderTopLeftRadius: '25px',
        }}
      />
    </aside>
  );
};

export default GoalsWindow;