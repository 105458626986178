import { z } from "zod";

const tooShort = { message: "This email is too short" };
const tooLong = { message: "This email is too long" };
const badPasLenght = {
  message: "The password should be between 8 and 24 characters long",
};

const getLength = (arr: string[] | null) => (arr ? arr.length : 0);

const defLogObject = {
  email: z
    .string()
    .email({ message: "This is not a valid email" })
    .max(50, tooLong)
    .min(5, tooShort)
    //a small step towards xss protection
    .refine((value) => !value.includes("<") && !value.includes(">"), {
      message: "The email contains prohibited characters",
    }),
  password: z
    .string()
    .max(24, badPasLenght)
    .min(8, badPasLenght)
    .refine(
      (value) => {
        const letters: string[] | null = value.match(/[a-zA-Z]/g);
        const numbers: string[] | null = value.match(/[0-9]/g);
        return getLength(letters) >= 1 && getLength(numbers) >= 1;
      },
      {
        message: "The password must contain at least 1 letter and 1 number",
      },
    ),
};

export const getValSchema = (isLogining: boolean) => {
  if (isLogining) {
    return z.object(defLogObject);
  }
  return z.object({
    ...defLogObject,
    license: z.literal(true, {
      errorMap: () => ({
        message: "You have not accepted the license",
      }),
    }),
  });
};
