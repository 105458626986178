import type { StateCreator } from "zustand";

interface State {
  loggedIn: boolean;
  email: string;
  password: string;
  nick: string;
  code: string;
  currency: string;
}

interface Actions {
  setLoggedIn: (email: State["loggedIn"]) => void;
  setEmail: (email: State["email"]) => void;
  setPassword: (password: State["password"]) => void;
  setNick: (nick: State["nick"]) => void;
  setCode: (code: State["code"]) => void;
  setCurrency: (currency: State["currency"]) => void;
}

export interface LoginSlice extends State, Actions {}

export const createLoginSlice: StateCreator<LoginSlice> = (set) => ({
  loggedIn: false,
  email: "",
  password: "",
  nick: "",
  code: "",
  currency: "",
  setLoggedIn: (loggedIn) => set(() => ({ loggedIn })),
  setEmail: (email) => set(() => ({ email })),
  setPassword: (password) => set(() => ({ password })),
  setNick: (nick) => set(() => ({ nick })),
  setCode: (code) => set(() => ({ code })),
  setCurrency: (currency) => set(() => ({ currency })),
});
