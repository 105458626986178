import { create } from "zustand";
import { createLoginSlice, type LoginSlice } from "./loginSlice";
import { createDialogSlice, type DialogSlice } from "./dialogSlice";
import { createAccountSlice, type AccountSlice } from "./AccountSlice";
import { createCategoriesSlice, type CategoriesSlice } from "./categoriesSlice";
import { createSpaceSlice, type SpaceSlice } from "./spaceSlice";
import { createSpinnerSlice, type SpinnerSlice } from "./spinnerSlice";

const useStore = create<
  LoginSlice &
    DialogSlice &
    AccountSlice &
    CategoriesSlice &
    SpaceSlice &
    SpinnerSlice
>((...a) => ({
  ...createLoginSlice(...a),
  ...createDialogSlice(...a),
  ...createAccountSlice(...a),
  ...createCategoriesSlice(...a),
  ...createSpaceSlice(...a),
  ...createSpinnerSlice(...a),
}));

export default useStore;
