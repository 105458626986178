import type { FC } from "react";
import React from "react";

interface Props {
  title: string;
  header: string;
  image: string;
  className?: string;
}

const Title: FC<Props> = React.memo(({ title, header, image, className }) => {
  return (
    <div
      className={`${className} flex bg-[#181818] col-[1/2] px-12 items-center rounded-tl-3xl`}
    >
      <img className="w-[7rem]" src={image} alt="is loaging" />
      <div className="ml-6">
        <p className="text-[30px] text-white">{header}</p>
        <p className="text-[16px] text-[#6E6E6E] whitespace-pre-line">
          {title}
        </p>
      </div>
    </div>
  );
});

export default Title;
